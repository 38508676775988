import { Flex, NormalText, Button } from '@curvo/common-ui'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components'
import { MeWithoutPaymentConsumer, MeWithoutPaymentProvider } from '@curvo/apollo'
import LoadingIndicator from './LoadingIndicator'

const defaultProps = {}

const initialState = {
  hasError: false,
}

type Props = typeof defaultProps & RouteComponentProps & {}

type State = Readonly<typeof initialState>

const ERROR_UNAUTHORIZED = 'Access denied! You need to be authorized to perform this action!'

const Container = styled(Flex)`
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
`

class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  static getDerivedStateFromError() {
    return {
      hasError: true,
    }
  }

  componentDidCatch(error: any) {
    if (error.message === ERROR_UNAUTHORIZED) {
      this.props.history.push('/auth/signin', {
        message: 'Your session has expired!',
        from: this.props.location.pathname,
      })
      return
    }
  }

  render() {
    const { history } = this.props
    if (this.state.hasError) {
      // TODO: Need design for error page
      return (
        <MeWithoutPaymentProvider>
          <MeWithoutPaymentConsumer>
            {({ data, loading }) => {
              if (loading) {
                return <LoadingIndicator />
              }

              if (!loading && !data) {
                localStorage.clear()
                this.props.history.push('/auth/signin', {
                  message: 'Your session has expired!',
                  from: this.props.location.pathname,
                })
                return
              }

              return (
                <Container flexDirection="column">
                  <NormalText danger size="2rem">
                    Error!!! Something went wrong!!!
                  </NormalText>
                  <Flex flexDirection="row">
                    <StyledButton onClick={() => history.go(0)}>Reload</StyledButton>
                    <Button
                      onClick={() => {
                        localStorage.clear()
                        history.push('/auth/signin', {
                          message: 'Something went wrong, please try to login again!',
                          from: history.location.pathname,
                        })
                        window.location.reload()
                      }}>
                      Retry login
                    </Button>
                  </Flex>
                </Container>
              )
            }}
          </MeWithoutPaymentConsumer>
        </MeWithoutPaymentProvider>
      )
    }
    return this.props.children
  }
}

const StyledButton = styled(Button)`
  margin-right: 1em;
`

export default withRouter(ErrorBoundary)
