import { MeConsumerData, StripeProductEnum, UpcomingInvoice } from '@curvo/apollo'
import { Button, Card, Colors, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PlanIcon } from '../../../components/Icons'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { formatPrice } from '../Billing'
import { BorderLineHorizontal, BorderLineVertical } from '../Profile'
import { PriceTag } from './Plan'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  data: MeConsumerData
}

const PlanInfo = styled(Flex)`
  flex-direction: column;
  min-width: 230px;
`

const PlanType = styled(Flex)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 7px;
  border: 1px solid ${Colors.Border};
  align-self: flex-start;
  padding: 1rem;
  position: relative;
  padding: 1.5rem;
  padding-left: calc(63px + 1.75rem);
  overflow-y: hidden;
  & > svg {
    position: absolute;
    bottom: 0;
    left: 1rem;
  }
`

export const formatDate = (timestame: number) => new Date(timestame * 1000).toDateString()

const SubscriptionDetails: React.SFC<Props> = ({ data }) => (
  <Card padding="2rem 2rem 1rem 2rem" marginBottom="2rem">
    <Flex flexDirection="row">
      <Flex flex={1} flexDirection="column">
        <NormalText size="1.125rem">Subscription Plans</NormalText>
        <Flex>
          {data.subscriptions.length ? (
            data.subscriptions.map(item => (
              <Flex flexDirection="column" key={item.id} flex="1">
                <PlanType>
                  <PlanIcon />
                  <NormalText size="1.5rem">
                    {item.plan!.productType === StripeProductEnum.Newsletter ? 'Newsletter' : 'Find A Part'}
                  </NormalText>
                </PlanType>
                <NormalText size="1.125rem">{`You're using ${item.plan!.nickname} plan`}</NormalText>
                <NormalText secondary>
                  {item.cancelAtPeriodEnd
                    ? `(Active due to ${formatDate(item.currentPeriodEnd)})`
                    : `${formatDate(item.currentPeriodStart)} - ${formatDate(item.currentPeriodEnd)}`}
                </NormalText>
                {item.plan!.productType === StripeProductEnum.FindAPart && (
                  <Flex>
                    <NormalText>You have used&nbsp;</NormalText>
                    <NormalText bold>{`${item.queryCount}/${item.totalQueries} `}</NormalText>
                    <NormalText>&nbsp;searches</NormalText>
                  </Flex>
                )}
              </Flex>
            ))
          ) : (
            <NormalText>You don't have any subscription plan</NormalText>
          )}
        </Flex>
      </Flex>
      <BorderLineVertical />
      <PlanInfo>
        {!!data.subscriptions.length && (
          <UpcomingInvoice selfHandleError>
            {({ data: invoiceData, loading, error }) => {
              if (error) {
                return <NormalText>No upcoming invoice</NormalText>
              }
              if (!invoiceData || loading) {
                return <LoadingIndicator />
              }
              return (
                <React.Fragment>
                  <PriceTag>${formatPrice(invoiceData.upcomingInvoice.total)}</PriceTag>
                  <NormalText>Next Bill</NormalText>
                  <NormalText>on {formatDate(invoiceData.upcomingInvoice.date)}</NormalText>
                </React.Fragment>
              )
            }}
          </UpcomingInvoice>
        )}
        <Link
          to={{
            pathname: '/setup/subscription',
            state: { ref: '/account/profile' },
          }}>
          <Button primary large block marginTop="1.5rem">
            Change Plans
          </Button>{' '}
        </Link>
      </PlanInfo>
    </Flex>
    {data && (
      <React.Fragment>
        <BorderLineHorizontal />
        <Link to="/account/billing">
          <Button link block>
            View all Invoices
          </Button>
        </Link>
      </React.Fragment>
    )}
  </Card>
)

SubscriptionDetails.defaultProps = defaultProps

export default SubscriptionDetails
