import { deleteChildUser, updateChildUserInfo, User, UserActivationStateEnum } from '@curvo/apollo'
import { AlertButton, Button, Colors, Flex, Gravatar, Modal, Notify, Toggle } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import UserForm from './UserForm'

const StyledAction = styled(Flex)`
  padding: 0px 2rem;
  flex: 1;
  justify-content: flex-end;
`

const StyledItem = styled(Flex)`
  flex: 1;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  height: 96px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: border-color ease-in-out 0.1s;
  &:hover {
    border: 1px solid #d0d9dc;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  }
`

const StyledStatus = styled('label')<{ isActive?: UserActivationStateEnum }>`
  font-weight: 500;
  flex: 1;
  color: ${props => {
    switch (props.isActive) {
      case UserActivationStateEnum.Active:
        return Colors.Success
      case UserActivationStateEnum.Deactive:
        return Colors.Danger
      default:
        return Colors.Gray
    }
  }};
`

const StyledAvatar = styled(Flex)`
  flex: 1;
  align-items: center;
`

const StyledUsername = styled('span')`
  font-size: 16px;
  font-weight: 500;
`

const StyledEmail = styled('span')`
  flex: 1;
`

type Props = {
  user: User
}

const validationSchema = () => {
  const shape = {
    email: Yup.string().email('Email is not valid!'),
  }
  return Yup.object().shape(shape)
}

const AccountItem: React.SFC<Props> = ({ user }) => {
  const initialValues = { ...user }

  type Values = typeof initialValues & {
    formError?: string
  }

  const handleEditAccount =
    (onRequestToClose: () => void) =>
    async ({ formError: _formError, ...data }: Values, { setSubmitting }: FormikActions<Values>) => {
      setSubmitting(true)
      try {
        await updateChildUserInfo({
          ...data,
          isActive: data.isActive === 'Active' ? true : false,
        })
        setSubmitting(false)
        Notify.success('Update account info successfully!')
        onRequestToClose()
      } catch (error) {
        console.error(error)
        Notify.danger(error.message || 'Something went wrong!')
        setSubmitting(false)
      }
    }
  return (
    <StyledItem>
      <StyledAvatar>
        <Gravatar email={user.email} marginLeft="1.5rem" marginRight="1.5rem" size="3rem" />
        <StyledUsername>{user.username}</StyledUsername>
      </StyledAvatar>
      <StyledStatus isActive={user.isActive}>{user.isActive}</StyledStatus>
      <StyledEmail>{user.email}</StyledEmail>
      <StyledAction>
        {user.isActive === UserActivationStateEnum.Active && (
          <>
            <Toggle>
              {({ isActive, on, off }) => (
                <React.Fragment>
                  <Button light minWidth="120px" paddingLeft="1.5rem" paddingRight="1.5rem" onClick={on}>
                    Edit
                  </Button>
                  <Modal isOpen={isActive} onRequestToClose={off}>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values, actions) => {
                        handleEditAccount(off)({ ...values }, { ...actions })
                      }}
                      validationSchema={validationSchema}>
                      {props => <UserForm isEditting {...props} data={user} />}
                    </Formik>
                  </Modal>
                </React.Fragment>
              )}
            </Toggle>
            <AlertButton
              alertTitle="Are you sure to deactivate this account?"
              alertMessage=""
              minWidth="120px"
              outline
              danger
              paddingLeft="1.5rem"
              paddingRight="1.5rem"
              marginLeft="1rem"
              onConfirm={() =>
                updateChildUserInfo({
                  id: user.id,
                  isActive: false,
                })
                  .then(() => Notify.success('Deactivate account successfully!'))
                  .catch(error => Notify.danger(error.message || 'Something went wrong!'))
              }>
              Deactivate
            </AlertButton>
          </>
        )}
        {user.isActive === UserActivationStateEnum.Deactive && (
          <AlertButton
            alertTitle="Are you sure to activate this account?"
            alertMessage=""
            minWidth="120px"
            outline
            success
            paddingLeft="1.5rem"
            paddingRight="1.5rem"
            marginLeft="1rem"
            onConfirm={() =>
              updateChildUserInfo({
                id: user.id,
                isActive: true,
              })
                .then(() => Notify.success('Activate account successfully!'))
                .catch(error => Notify.danger(error.message || 'Something went wrong!'))
            }>
            Activate
          </AlertButton>
        )}
        {user.isActive === UserActivationStateEnum.Pending && (
          <AlertButton
            alertTitle="Are you sure to delete this account?"
            alertMessage=""
            minWidth="120px"
            outline
            danger
            paddingLeft="1.5rem"
            paddingRight="1.5rem"
            marginLeft="1rem"
            onConfirm={() =>
              deleteChildUser({ id: user.id }).then(() => Notify.success('Delete account successfully!'))
            }>
            Delete
          </AlertButton>
        )}
      </StyledAction>
    </StyledItem>
  )
}

export default AccountItem
