import { AveragePrice } from '@curvo/apollo'
import { Colors, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
// import { ArrowIcon } from '../../../components/Icons'
import styled from 'styled-components'

const defaultProps = {}

const initialState = {}

type Props = Partial<typeof defaultProps> & {
  data?: AveragePrice[] | null
}

type PricesType = {
  [key: number]: AveragePrice & { isPartialYear: boolean }
}

type State = Readonly<
  typeof initialState & {
    selectedYear?: number
    prices?: PricesType
  }
>

const Text = styled(NormalText)`
  line-height: normal;
`
const ChartGroup = styled(Flex)`
  display: none;
  padding: 1rem;
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  min-width: 350px;
  background: #ffffff;
  border: 1px solid rgba(137, 157, 166, 0.28);
  box-shadow: 0 2px 8px 0 rgba(89, 99, 109, 0.1);
  align-items: center;
  justify-items: center;
  border-radius: 3px;
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 6px solid transparent;
    border-bottom: 6px solid rgba(137, 157, 166, 0.28);
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-bottom: 5px solid white;
  }
`
const HoverTextPopup = styled(ChartGroup)`
  min-width: 180px;
`
const PriceQtyHospContainer = styled(Flex)`
  max-width: 354px;
`
const PriceContainer = styled(Flex)`
  position: relative;
  cursor: pointer;
  &:hover {
    ${ChartGroup} {
      display: block;
    }
  }
`

const YearLabel = styled(Text)<{
  isDisabled?: boolean
  isPartialYear?: boolean
}>`
  ${props => (props.isDisabled ? 'text-decoration: line-through;' : undefined)}
  ${props =>
    !props.isDisabled
      ? `
  cursor: pointer;
  &:hover {
    color: ${Colors.Primary};
  }`
      : undefined}
  ${props => props.isPartialYear && 'font-style: italic;'}
`

const transformPriceData = (data: any[] | null | undefined): PricesType | undefined => {
  if (data) {
    const [minYear, maxYear] = data.reduce(
      ([prevMinYear, prevMaxYear], currentItem) => {
        const currentYear =
          currentItem.periodType.length > 4 ? +currentItem.periodType.slice(-4) : +currentItem.periodType
        if (currentYear) {
          let newMinYear = prevMinYear
          let newMaxYear = prevMaxYear
          if (newMinYear === 0 || (newMinYear !== 0 && newMinYear > currentYear)) {
            newMinYear = currentYear
          }
          if (newMaxYear === 0 || (newMaxYear !== 0 && newMaxYear < currentYear)) {
            newMaxYear = currentYear
          }
          return [newMinYear, newMaxYear]
        }
        return [prevMinYear, prevMaxYear]
      },
      [0, 0] as number[],
    )

    const yearInRange = Array.from({ length: maxYear - minYear + 1 }).map((_, yearIndex) => maxYear - yearIndex)

    data.map(price => {
      return {
        ...price,
        isPartialYear: !Number.isInteger(+price.periodType[0]),
      }
    })

    return yearInRange.reduce(
      (previousResult, currentItem) => ({
        ...previousResult,
        [currentItem]: data.filter(
          price =>
            currentItem === (price.periodType.length > 4 ? +price.periodType.slice(-4) : +price.periodType) &&
            price.averagePrice !== 0,
        ),
      }),
      {},
    )
  }
  return undefined
}

export const numberFormat = data => new Intl.NumberFormat('en-US').format(data)

const PriceTag: React.FC<{ price: number }> = ({ price, children }) => {
  const [value, float] = price.toString().split('.')
  let extendFloat = float
  if (float && float.length === 1) {
    extendFloat += '0'
  }
  return (
    <PriceContainer alignItems="flex-start" marginRight="1rem">
      <Text size="2.25rem">${numberFormat(value)}</Text>
      <Text marginTop="0.625rem" marginLeft="0.375rem" secondary>
        {float ? extendFloat : '00'}
      </Text>
      {children}
    </PriceContainer>
  )
}
const EmptyPriceTag: React.FC<{ hospitalCount: number }> = ({ children, hospitalCount }) => (
  <PriceContainer alignItems="flex-start" marginRight="1rem">
    <Text size="2.25rem">{hospitalCount > 0 ? '$*' : '$--'}</Text>
    <Text marginTop="0.625rem" marginLeft="0.375rem" secondary>
      {hospitalCount > 0 ? '' : '--'}
    </Text>
    {children}
  </PriceContainer>
)

export const deduplicateByKey = (array: any[], key: string) =>
  array.reduce((previousResult, currentItem) => {
    const isValueExists = previousResult.find(item => item[key] === currentItem[key])
    if (isValueExists) {
      return previousResult
    }
    return [...previousResult, currentItem]
  }, [])

export default class Prices extends React.Component<Props, State> {
  static defaultProps = defaultProps

  constructor(props: Props) {
    super(props)
    const prices = transformPriceData(props.data)
    const selectedYear = prices
      ? Object.keys(prices).reduce((previousResult, item) => {
          if (prices[item].length) {
            return +item
          }
          return previousResult
        }, 0)
      : undefined
    this.state = {
      selectedYear,
      prices,
    }
  }

  private handleChangeYear = (year: number) => this.setState({ selectedYear: year })

  render() {
    const { prices, selectedYear } = this.state
    if (!prices || !selectedYear) {
      return (
        <Flex flexDirection="column" marginTop="16px" flex={1}>
          <Text uppercase secondary>
            Average Selling Price
          </Text>
          <Text size="2.25rem">-</Text>
        </Flex>
      )
    }
    const years = prices ? Object.keys(prices).sort((a, b) => (+a > +b ? -1 : 1)) : []
    return (
      <Flex flexDirection="column" marginTop="16px" flex={1}>
        <Flex flexDirection="column">
          <Text uppercase secondary>
            Average Selling Price{' '}
            {prices[selectedYear][0] &&
              prices[selectedYear][0].isPartialYear &&
              `(${prices[selectedYear][0].periodType})`}
          </Text>
          <PriceQtyHospContainer justifyContent="space-between">
            {prices[selectedYear][0].hospitalCount >= 5 ? (
              <PriceTag price={prices[selectedYear][0].averagePrice}>
                <ChartGroup>{this.props.children}</ChartGroup>
              </PriceTag>
            ) : (
              <EmptyPriceTag hospitalCount={prices[selectedYear][0].hospitalCount}>
                <HoverTextPopup>
                  <Text center>Not enough Hospitals</Text>
                </HoverTextPopup>
              </EmptyPriceTag>
            )}

            <Flex alignItems="center" flexWrap="wrap">
              <Text secondary uppercase>
                QTY:&nbsp;
              </Text>
              <Text>{numberFormat(+prices[selectedYear][0].quantity)}</Text>
            </Flex>
            <Flex alignItems="center" flexWrap="wrap">
              <Text secondary uppercase>
                HOSP:&nbsp;
              </Text>
              <Text>{numberFormat(prices[selectedYear][0].hospitalCount)}</Text>
            </Flex>
          </PriceQtyHospContainer>
          {/* <Flex alignItems="center">
            <ArrowIcon />
            <Text danger marginLeft="0.5rem">
              -15%
            </Text>
          </Flex> */}
        </Flex>
        <Flex flexWrap="wrap">
          {!!years.length &&
            years.map((year, yearIndex) => {
              const disabled = prices[year] && prices[year].length ? false : true
              const partialYear = prices[year][0] ? prices[year][0].isPartialYear : false
              const handleYearClick = (event: React.MouseEvent<any>) => {
                event.stopPropagation()
                if (!disabled) {
                  this.handleChangeYear(+year)
                }
              }
              return (
                <YearLabel
                  marginRight={yearIndex + 1 !== years.length ? '1rem' : undefined}
                  key={year}
                  isDisabled={disabled}
                  isPartialYear={partialYear}
                  secondary={+year !== +selectedYear || disabled}
                  onClick={handleYearClick}>
                  {year}
                  {partialYear && '*'}
                </YearLabel>
              )
            })}
        </Flex>
      </Flex>
    )
  }
}
