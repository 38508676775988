import { Alert, Button, TextInput } from '@curvo/common-ui'
import { FormikHandlers, FormikState } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Values } from './SignIn'

const defaultProps = {}

type Props = typeof defaultProps & FormikState<Values> & FormikHandlers

export const Form = styled('form')``

const SignInForm: React.SFC<Props> = ({
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
}) => (
  <Form onSubmit={handleSubmit}>
    {values.formError && (
      <Alert danger marginBottom="1rem">
        {values.formError}
      </Alert>
    )}
    <TextInput
      placeholder="Email or Username"
      name="username"
      value={values.username}
      onChange={handleChange}
      onBlur={handleBlur}
      error={(touched.username && errors.username) || undefined}
      marginBottom="1rem"
    />
    <TextInput
      type="password"
      placeholder="Enter your Password"
      name="password"
      value={values.password}
      onChange={handleChange}
      onBlur={handleBlur}
      error={(touched.password && errors.password) || undefined}
      marginBottom="1rem"
    />
    {values.newPasswordRequired && (
      <React.Fragment>
        <TextInput
          type="password"
          placeholder="Enter New Password"
          name="newPassword"
          value={values.newPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={(touched.newPassword && errors.newPassword) || undefined}
          marginBottom="1rem"
        />
        <TextInput
          type="password"
          placeholder="Confirm Your New Password"
          name="confirmNewPassword"
          value={values.confirmNewPassword}
          onBlur={handleBlur}
          onChange={handleChange}
          error={(touched.confirmNewPassword && errors.confirmNewPassword) || undefined}
          marginBottom="1rem"
        />
      </React.Fragment>
    )}
    <Button primary block large marginBottom="1rem" type="submit" loading={isSubmitting}>
      Login
    </Button>
  </Form>
)

SignInForm.defaultProps = defaultProps

export default SignInForm
