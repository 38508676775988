import React from 'react'
import styled from 'styled-components'
import { Flex, NormalText } from '@curvo/common-ui'
import PageNavigation from './PageNavigation'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {}

const Container = styled(Flex)`
  width: 100%;
`

const PageLayout: React.SFC<Props> = ({ children }) => (
  <Container flexDirection="column">
    <NormalText size="2rem">Account</NormalText>
    <PageNavigation />
    {children}
  </Container>
)

PageLayout.defaultProps = defaultProps

export default PageLayout
