import React from 'react'
import { RouteComponentProps, Switch } from 'react-router'
import AuthorizedRoute from '../../components/AuthorizedRoute'
import Homepage from './Homepage'
import SearchResult from './SearchResult'

const defaultProps = {}

type Props = typeof defaultProps & RouteComponentProps & {}

const Home: React.SFC<Props> = () => {
  return (
    <Switch>
      <AuthorizedRoute path="/" exact component={Homepage} />
      <AuthorizedRoute path="/search" exact component={SearchResult} />
    </Switch>
  )
}

Home.defaultProps = defaultProps

export default Home
