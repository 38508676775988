import { MeContext } from '@curvo/apollo'
import dateFns from 'date-fns'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '@curvo/common-ui'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin-bottom: 32px;
  border: 2px solid ${Colors.Primary};
  border-radius: 8px;
  p {
    &:first-child {
      font-size: 24px;
      margin-bottom: 8px;
      span {
        font-weight: bold;
        font-size: inherit;
        color: ${Colors.Danger};
      }
    }
    a {
      color: ${Colors.Primary};
    }
  }
`

export const LegacyUserWarning: React.FC = () => {
  const me = useContext(MeContext)
  const expirationDate = me && me.data && me.data.me.expirationDate

  return expirationDate ? (
    <Container>
      <p>
        You’re account is valid until <span>{dateFns.format(expirationDate, 'MM/DD/YYYY')}</span>
      </p>
      <p>
        After that date, you will need to create a new account at:{' '}
        <Link to="/auth/signup">{`${window.location.origin}/auth/signup/`}</Link>
      </p>
    </Container>
  ) : null
}
