import { Button, Flex } from '@curvo/common-ui'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { SearchIcon } from '../../../components/Icons'
import { SearchMode } from '../Homepage'
import TextInputWithSuggest from './TextInputWithSuggest'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & RouteComponentProps & {}

const initialValues = {
  searchText: '',
}

type Values = typeof initialValues

const Form = styled('form')`
  width: 100%;
`

const BasicMode: React.SFC<Props> = ({ history }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values: Values) => {
      history.push({
        pathname: '/search',
        state: {
          searchMode: SearchMode.Basic,
          searchText: values.searchText,
        },
      })
    }}
    render={({ values, handleSubmit, submitForm, setFieldValue }: FormikProps<Values>) => (
      <Form onSubmit={handleSubmit}>
        <Flex>
          <TextInputWithSuggest
            inputProps={{
              placeholder: 'Enter Part Number...',
              showError: false,
              large: true,
              flex: 1,
              name: 'searchText',
              autoComplete: 'off',
            }}
            value={values.searchText}
            marginRight="1.5rem"
            setFieldValue={setFieldValue}
            submitForm={submitForm}
            fieldValue="searchText"
          />
          <Button paddingLeft="2.625rem" paddingRight="2.625rem" type="submit" disabled={values.searchText.length <= 0}>
            <SearchIcon /> Search
          </Button>
        </Flex>
      </Form>
    )}
  />
)

BasicMode.defaultProps = defaultProps

export default withRouter(BasicMode)
