import { Colors, darken, Flex, getMargin, MarginProps, NormalText } from '@curvo/common-ui'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CurvoLogo from '../assets/images/curvo-logo.svg'
import env from '../configs/env'
import UserMenu from './UserMenu'

type Props = {
  title?: string
  transparent?: boolean
  isLogoClickable?: boolean
  showName?: boolean
} & MarginProps

const Container = styled(Flex)<{ transparent?: boolean }>`
  width: 100%;
  height: 80px;
  padding: 1.625rem 2rem;
  background: ${props => (props.transparent ? 'transparent' : Colors.White)};
  box-shadow: ${props => (props.transparent ? 'none' : '0 1px 4px 0 rgba(0, 0, 0, 0.06)')};
  align-items: center;
  margin-bottom: 3.75rem;
  ${getMargin}
`

const Logo = styled('img')`
  height: 30px;
  width: auto;
  margin-right: 2rem;
`

const Title = styled(NormalText)`
  padding-left: 2rem;
  border-left: 1px solid ${Colors.Border};
`

const NavLink = styled.div`
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;
`
const NavText = styled.a`
  font-weight: bold;
  font-size: 1.5em;
  color: ${Colors.Black};

  &:hover {
    color: ${Colors.Primary};
  }
  &:active {
    color: ${darken(Colors.Primary, 0.5)};
  }
`

const Header: React.SFC<Props> = ({
  title,
  children,
  transparent,
  isLogoClickable = true,
  showName = true,
  ...props
}) => {
  return (
    <Container transparent={transparent} alignItems="center" {...props}>
      {isLogoClickable && (
        <Link to="/">
          <Logo src={CurvoLogo} />
        </Link>
      )}
      {title && <Title size="2rem">{title}</Title>}
      {children}
      <Flex marginLeft="auto">
        <NavLink>
          <NavText href={env.LANDING_PAGE_LINK}>Home</NavText>
        </NavLink>
        <NavLink>
          <Link to="/">
            <NavText>Find a Part</NavText>
          </Link>
        </NavLink>
        <NavLink>
          <NavText href={`${env.LANDING_PAGE_LINK}newsletter`}>Newsletters</NavText>
        </NavLink>

        <Flex marginLeft={20} />

        <UserMenu showName={showName} />
      </Flex>
    </Container>
  )
}

export default Header
