import styled from 'styled-components'
import { Rotate360, Colors, getMargin, MarginProps } from '@curvo/common-ui'

type Props = {
  color?: string
  size?: number
  center?: boolean
} & MarginProps

const LoadingIndicator = styled.div<Props>`
  ${props => (props.center ? 'margin: 0 auto' : '')};
  width: ${props => (props.size ? `${props.size}px` : '16px')};
  height: ${props => (props.size ? `${props.size}px` : '16px')};
  position: relative;
  ${getMargin};
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    border: 2px solid ${props => (props.color ? props.color : Colors.Primary)};
    width: 80%;
    height: 80%;
    border-top-color: transparent;
    border-right-color: transparent;
    animation: ${Rotate360} 500ms infinite linear;
    border-radius: 50%;
  }
`

export default LoadingIndicator
