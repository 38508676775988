import { MeData, StripeCouponType, StripeInvoiceType } from '@curvo/apollo'
import { Button, Flex, Grid, NormalText } from '@curvo/common-ui'
import React from 'react'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { formatPrice } from '../Billing'
import { BorderLineHorizontal } from '../Profile'
import { Container } from './ChangePasswordForm'
import { formatDate } from './SubscriptionDetails'

const defaultProps = {}

type Props = typeof defaultProps & {
  data: StripeInvoiceType
  onRequestToClose?: FuncVoid
  isBilled?: boolean
}

const buildCouponName = ({ name, percentOff }: StripeCouponType): string =>
  percentOff ? `${name} (${percentOff}%)` : name

export const buildPercentageDiscount = (total: number, { percentOff }: StripeCouponType): string => {
  console.info({ total, percentOff })
  return percentOff ? `-$${formatPrice(total * (percentOff / 100))}` : ''
}

const InvoiceDetails: React.SFC<Props> = ({ data, onRequestToClose, isBilled = true }) => (
  <MeData>
    {({ data: meData, loading: meLoading }) => {
      if (!meData || meLoading) {
        return <LoadingIndicator />
      }
      return (
        <Container justifyContent="space-between">
          <Flex flexDirection="column">
            <Flex justifyContent="space-between">
              <NormalText size="1.2rem">Invoice</NormalText>
              <NormalText size="1.2rem">${formatPrice(data.total)}</NormalText>
            </Flex>
            <BorderLineHorizontal marginTop="1.5rem" marginBottom="2rem" />
            {isBilled && (
              <React.Fragment>
                <Grid gridTemplate="repeat(2, 1fr) / repeat(2, 1fr)" gridGap="1rem">
                  <Flex flexDirection="column">
                    <NormalText secondary size="0.875rem">
                      Billed to
                    </NormalText>
                    <NormalText>{`${meData.me.firstName} ${meData.me.lastName}`}</NormalText>
                  </Flex>
                  <Flex flexDirection="column">
                    <NormalText secondary>Date</NormalText>
                    <NormalText>{formatDate(data.date)}</NormalText>
                  </Flex>
                  <Flex flexDirection="column">
                    <NormalText secondary>Account</NormalText>
                    <NormalText>{meData.me.email}</NormalText>
                  </Flex>
                  <Flex flexDirection="column">
                    <NormalText secondary>Invoice ID</NormalText>
                    <NormalText>{data.number}</NormalText>
                  </Flex>
                </Grid>
                <BorderLineHorizontal marginTop="2rem" marginBottom="2rem" />
              </React.Fragment>
            )}
            {data.lines.data.map((line, lineIndex) => (
              <Flex justifyContent="space-between" alignItems="center" key={`line-${lineIndex}`}>
                <Flex flexDirection="column">
                  <NormalText>{line.description}</NormalText>
                </Flex>
                <NormalText>${formatPrice(line.amount)}</NormalText>
              </Flex>
            ))}
          </Flex>
          <Flex flexDirection="column">
            <Grid gridTemplate="1fr / repeat(2, 1fr)" marginTop="2rem">
              <div />
              <Flex flexDirection="column">
                {data.discount && (
                  <Flex justifyContent="space-between">
                    <NormalText flex={1}>{buildCouponName(data.discount.coupon)}</NormalText>
                    <NormalText flex={1} justifyContent="flex-end">
                      {data.discount.coupon.amountOff
                        ? `-$${formatPrice(data.discount.coupon.amountOff)}`
                        : buildPercentageDiscount(data.subtotal, data.discount.coupon)}
                    </NormalText>
                  </Flex>
                )}
                <BorderLineHorizontal marginTop="1rem" marginBottom="1rem" />
                <Flex justifyContent="space-between">
                  <NormalText>Total</NormalText>
                  <NormalText>${formatPrice(data.total)}</NormalText>
                </Flex>
              </Flex>
            </Grid>
            <BorderLineHorizontal marginTop="2rem" marginBottom="2rem" />
            <Flex marginTop="1rem">
              <NormalText secondary>Have a question or need help?</NormalText>
              <a href="mailto:customersuccess@curvolabs.com" target="_newtab">
                <Button link onClick={onRequestToClose}>
                  Contact support
                </Button>
              </a>
            </Flex>
            <Flex alignSelf="flex-end" marginTop="1rem">
              {data.invoicePdf && (
                <Button block marginRight="1rem">
                  <a href={data.invoicePdf}>Download Invoice</a>
                </Button>
              )}
            </Flex>
          </Flex>
        </Container>
      )
    }}
  </MeData>
)

InvoiceDetails.defaultProps = defaultProps

export default InvoiceDetails
