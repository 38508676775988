import styled from 'styled-components'
import { Flex, Colors, elementSize } from '@curvo/common-ui'

const CardInput = styled(Flex)`
  flex-direction: column;
  width: 100%;
  color: ${Colors.Dark};
  border: 1px solid ${Colors.Border};
  border-radius: 3px;
  position: relative;
  background: ${Colors.InputBackground};
  transition: background 0.2s ease-in-out;
  &:after {
    position: absolute;
    bottom: -1px;
    border-radius: 0 0 3px 3px;
    left: 0;
    width: 100%;
    height: 3px;
    content: '';
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background: ${Colors.Primary};
  }
  &:focus {
    background: ${Colors.White};
    &:after {
      opacity: 1;
      z-index: 10;
    }
  }
  & > * {
    border-radius: 3px;
    ${elementSize};
  }
  & > *:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & > *:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export default CardInput
