import { StripeCardType } from '@curvo/apollo'
import { Button, Card, Colors, Flex, Modal, NormalText, Toggle } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import CardBrand from '../../../components/CardBrand'
import { CardNumberPlaceholder } from '../../../components/Icons'
import AddNewCardForm from './AddNewCardForm'
import EditCardForm from './EditCardForm'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  cards: StripeCardType[]
  defaultSource?: string
}

const ListCardContainer = styled(Flex)`
  flex-wrap: nowrap;
  overflow-y: auto;
`

const CardContainter = styled(Card)`
  width: 305px;
  height: 193px;
  cursor: pointer;
  * {
    cursor: pointer;
  }
`

const AddNewCard = styled(Flex)`
  border: 1px dashed ${Colors.Border};
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 305px;
  height: 193px;
  cursor: pointer;
`

const PaymentMethods: React.SFC<Props> = ({ cards, defaultSource }) => (
  <Flex flexDirection="column" marginBottom="1.5rem">
    <NormalText size="1.2rem" marginBottom="1rem">
      Payment Methods
    </NormalText>
    <Flex>
      {!!cards.length && (
        <ListCardContainer>
          {cards.map((card, cardIndex) => {
            const isDefault = card.id === defaultSource
            const isLastChild = cardIndex + 1 === cards.length
            return (
              <Toggle key={card.id}>
                {({ isActive, on, off }) => (
                  <React.Fragment>
                    <CardContainter
                      padding="2rem"
                      marginBottom="0.5rem"
                      marginRight={isLastChild ? 0 : '0.5rem'}
                      onClick={on}>
                      <NormalText secondary marginBottom="1rem">
                        {isDefault && 'Default Method'}&nbsp;
                      </NormalText>
                      <CardBrand type={card.brand ? card.brand : ''} />
                      <NormalText marginTop="1rem" size="1.2rem">
                        <CardNumberPlaceholder />
                        &nbsp;&nbsp;&nbsp;{card.last4}
                      </NormalText>
                    </CardContainter>
                    <Modal isOpen={isActive} onRequestToClose={off}>
                      <EditCardForm
                        card={card}
                        isDefault={isDefault}
                        onRequestToClose={off}
                        defaultCard={cards.find(item => item.id === defaultSource)!}
                      />
                    </Modal>
                  </React.Fragment>
                )}
              </Toggle>
            )
          })}
        </ListCardContainer>
      )}
      <Toggle>
        {({ isActive, on, off }) => (
          <AddNewCard onClick={on} marginLeft={cards.length ? '0.5rem' : 0}>
            <Button link>+ Add New Card</Button>
            <Modal isOpen={isActive} onRequestToClose={off}>
              <AddNewCardForm onRequestToClose={off} />
            </Modal>
          </AddNewCard>
        )}
      </Toggle>
    </Flex>
  </Flex>
)

PaymentMethods.defaultProps = defaultProps

export default PaymentMethods
