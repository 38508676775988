import {
  MeConsumer,
  StripePlanType,
  StripeProductEnum,
  StripeProductType,
  StripeSubscriptionType,
  SubscriptionProductsData,
  addSubscription,
  changeSubscription,
  removeSubscription,
} from '@curvo/apollo'
import { Button, Colors, Flex, NormalText, Notify, TextInput, darken } from '@curvo/common-ui'
import { isNil } from 'lodash'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import LoadingIndicator from '../../components/LoadingIndicator'
import Plan from './components/Plan'
import PlanStatic from './components/PlanStatic'

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
`

const Header = styled(Flex)`
  width: 600px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
`

const CustomButtom = styled(Button)`
  border: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  min-width: 355px;
  margin-bottom: 130px;
  padding: 1rem;
  text-align: center;
  justify-content: center;
`

const UseItButton = styled(Button)`
  padding: 4px 12px;
  background-color: ${Colors.White};
  border-color: ${Colors.Primary};
  color: ${Colors.Primary};

  &:active {
    background-color: ${darken(Colors.Primary, 0.1)};
  }
`
const defaultProps = {}

type Props = typeof defaultProps &
  RouteComponentProps<any, any, { ref?: string; coupon?: string; plans: StripePlanType[] }> & {}

const initialState = {
  couponCode: '',
  loading: false,
  isMonthly: false,
  isCoupon: false,
}

type State = Readonly<typeof initialState> & {
  selectedNewsletterPlan?: StripePlanType
  selectedFindAPartPlan?: StripePlanType
}

export default class Subscription extends React.Component<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  private getProductByType = (data: StripeProductType[], type: StripeProductEnum) =>
    data.find(item => item.type === type && item.active === true)

  private isSelectingPlans = () => {
    const { selectedNewsletterPlan, selectedFindAPartPlan, loading } = this.state
    return !!selectedNewsletterPlan || !!selectedFindAPartPlan || loading
  }

  private onSelectNewsletterPlan = (plan: StripePlanType) => {
    if (this.state.selectedNewsletterPlan && this.state.selectedNewsletterPlan.id === plan.id) {
      this.setState({ selectedNewsletterPlan: undefined })
    } else {
      this.setState({ selectedNewsletterPlan: plan })
    }
  }

  private onSelectFindAPartPlan = (plan: StripePlanType) => {
    if (this.state.selectedFindAPartPlan && this.state.selectedFindAPartPlan.id === plan.id) {
      this.setState({ selectedFindAPartPlan: undefined })
    } else {
      this.setState({ selectedFindAPartPlan: plan })
    }
  }

  private resetSelectedPlans = () => {
    this.setState({ selectedNewsletterPlan: undefined, selectedFindAPartPlan: undefined })
  }

  private updateSubcriptionPlans = async (subscribedItems: StripeSubscriptionType[]) => {
    const { updateSubcriptionPlan } = this
    const { selectedNewsletterPlan, selectedFindAPartPlan, loading } = this.state
    if (loading) {
      return
    }
    this.setState({ loading: true })

    let ifNewsletterPlanSet = false
    let ifFindAPartPlanSet = false

    // unsubscribe
    for (const subItem of subscribedItems) {
      const productType = subItem.plan && subItem.plan.productType
      if (productType === StripeProductEnum.Newsletter) {
        ifNewsletterPlanSet = true
        await updateSubcriptionPlan(selectedNewsletterPlan, subItem)
      } else if (productType === StripeProductEnum.FindAPart) {
        ifFindAPartPlanSet = true
        await updateSubcriptionPlan(selectedFindAPartPlan, subItem)
      }
    }

    // subscribe
    if (!ifNewsletterPlanSet) {
      await updateSubcriptionPlan(selectedNewsletterPlan)
    }
    if (!ifFindAPartPlanSet) {
      await updateSubcriptionPlan(selectedFindAPartPlan)
    }
    this.resetSelectedPlans()
    this.setState({ loading: false })
  }

  private updateSubcriptionPlan = async (newPlan?: StripePlanType, subscribedProduce?: StripeSubscriptionType) => {
    const couponCode = this.state.couponCode.length ? this.state.couponCode : undefined
    if (!newPlan) {
      return
    }
    if (isNil(subscribedProduce) || subscribedProduce.cancelAtPeriodEnd) {
      try {
        await addSubscription({ planId: newPlan.id, coupon: couponCode })
      } catch (e) {
        console.error(e)
        Notify.danger(e.message || 'Something went wrong!')
      }

      return
    }

    const subscribedPlan = subscribedProduce.plan
    if (isNil(subscribedPlan)) {
      return
    }
    if (subscribedPlan.id === newPlan.id) {
      try {
        await removeSubscription({ subId: subscribedProduce.id })
      } catch (e) {
        Notify.danger(e.message || 'Something went wrong!')
      }
    } else {
      try {
        await changeSubscription({ planId: newPlan.id, coupon: couponCode })
      } catch (e) {
        Notify.danger(e.message || 'Something went wrong!')
      }
    }
  }

  private getSelectedPlans = () => {
    const plans: StripePlanType[] = []
    if (this.state.selectedFindAPartPlan) {
      plans.push(this.state.selectedFindAPartPlan)
    }
    if (this.state.selectedNewsletterPlan) {
      plans.push(this.state.selectedNewsletterPlan)
    }
    return plans
  }

  // private getPlanByInterval = (
  //   data: StripePlanType[],
  //   interval: BillingIntervalEnum,
  // ) => data.find(item => item.interval === interval && item.active === true)

  // private handleBillingTypeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
  //   this.setState({ isMonthly: e.target.checked })

  render() {
    const { getProductByType } = this
    // const { isMonthly } = this.state
    const { loading: stateLoading, isCoupon, couponCode } = this.state

    const { location, history } = this.props
    return (
      <Container>
        <Header>
          <NormalText size="2rem" center marginBottom="2rem">
            Subscription
          </NormalText>
          <NormalText center>
            An Orthopedic Networks News by Curvo subscription gives you access to the gold standard in orthopedic
            content, classification, and categorization. We have laid out the pricing by subscription type so you can
            sign up for the one that best fits your business needs. Choose from a Newsletter only subscription, a
            Find-A-Part only subscription, or both.If you are looking to add multiple users, contact us for enterprise
            pricing.
          </NormalText>
        </Header>
        <MeConsumer>
          {({ data: meData, loading: meLoading }) => (
            <SubscriptionProductsData>
              {({ data, loading }) => {
                if (!meData || meLoading || !data || loading) {
                  return <LoadingIndicator center />
                }

                const newsletter = getProductByType(data.subscriptionProducts.data, StripeProductEnum.Newsletter)
                const findAPart = getProductByType(data.subscriptionProducts.data, StripeProductEnum.FindAPart)

                const newsletterPlans =
                  newsletter && newsletter.plans
                    ? newsletter.plans.filter(item => item.id.includes('public-') && item.active === true)
                    : []

                const findAPartPlans =
                  findAPart && findAPart.plans
                    ? findAPart.plans.filter(item => item.id.includes('public-') && item.active === true)
                    : []
                // const payAsYouGo = getPlanByInterval(
                //   findAPart!.plans!,
                //   BillingIntervalEnum.Week,
                // )
                const subscribedItems = meData.me.subscriptions.length
                  ? meData.me.subscriptions.reduce<StripeSubscriptionType[]>((previousResult, currentItem) => {
                      if (currentItem.plan) {
                        return [...previousResult, currentItem]
                      }
                      return previousResult
                    }, [])
                  : []

                const isSubscribed = subscribedItems.length ? true : false

                return (
                  <React.Fragment>
                    {/* <BillType
                      checked={isMonthly}
                      onChange={handleBillingTypeChange}
                    /> */}
                    <Flex marginTop="2rem">
                      {newsletterPlans.map(plan => (
                        <Plan
                          title="Newsletter"
                          data={plan}
                          subscribedItems={subscribedItems}
                          key={`news-${plan.id}`}
                          onSelect={this.onSelectNewsletterPlan}
                          selected={
                            this.state.selectedNewsletterPlan && this.state.selectedNewsletterPlan.id === plan.id
                          }
                          loading={meLoading || stateLoading}
                        />
                      ))}
                      {findAPartPlans
                        .sort((a, b) => (a.id > b.id ? 1 : -1))
                        .map(plan => (
                          <Plan
                            data={plan}
                            subscribedItems={subscribedItems}
                            key={`plan-${plan.id}`}
                            onSelect={this.onSelectFindAPartPlan}
                            selected={
                              this.state.selectedFindAPartPlan && this.state.selectedFindAPartPlan.id === plan.id
                            }
                            loading={meLoading || stateLoading}
                          />
                        ))}
                      <PlanStatic />
                    </Flex>
                    <Flex justifyContent="center" marginTop="24px" marginBottom="48px">
                      {isCoupon ? (
                        <React.Fragment>
                          <NormalText marginRight="8px">Coupon Code: </NormalText>
                          <TextInput
                            placeholder="Enter Coupon Code"
                            onChange={event => {
                              this.setState({ couponCode: event.target.value })
                            }}
                            value={couponCode}
                            showError={false}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <NormalText secondary marginRight="8px">
                            You have a Coupon Code?{' '}
                          </NormalText>
                          <UseItButton padding="4px 12px" onClick={() => this.setState({ isCoupon: true })}>
                            Use It
                          </UseItButton>
                        </React.Fragment>
                      )}
                    </Flex>
                    {this.isSelectingPlans() ? (
                      <Flex justifyContent="center">
                        <CustomButtom
                          primary
                          light={meLoading || stateLoading}
                          disabled={meLoading || stateLoading}
                          loading={meLoading || stateLoading}
                          onClick={() => {
                            if (!(meLoading || stateLoading) && meData.me.hasPaymentInfo) {
                              this.updateSubcriptionPlans(meData.me.subscriptions)
                            } else {
                              history.push('/setup/billing-info', {
                                coupon: couponCode,
                                plans: this.getSelectedPlans(),
                              })
                            }
                          }}>
                          Save Changes
                        </CustomButtom>
                        <CustomButtom
                          outline
                          disabled={meLoading || stateLoading}
                          onClick={() => this.resetSelectedPlans()}>
                          Reset
                        </CustomButtom>
                      </Flex>
                    ) : (
                      <Flex justifyContent="center">
                        <Link to={location.state && location.state.ref ? location.state.ref : '/'}>
                          <CustomButtom
                            outline
                            primary
                            loading={meLoading || stateLoading}
                            disabled={meLoading || stateLoading}>
                            {isSubscribed ? 'Go back' : 'Do this later'}
                          </CustomButtom>
                        </Link>
                      </Flex>
                    )}
                  </React.Fragment>
                )
              }}
            </SubscriptionProductsData>
          )}
        </MeConsumer>
      </Container>
    )
  }
}
