import { MeContext, UserTypeEnum } from '@curvo/apollo'
import { Colors, Flex } from '@curvo/common-ui'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const defaultProps = {}

type Props = typeof defaultProps & {}

const NavItem = styled(Flex)`
  margin-right: 2.5rem;
  a {
    color: ${Colors.Secondary};
    text-decoration: none;
    padding-bottom: 1rem;
    font-size: 1.2rem;
  }
  & > .active {
    color: ${Colors.Dark};
    border-bottom: 2px solid ${Colors.Primary};
    display: inline-block;
  }
`

const Navigation = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${Colors.Border};
  margin: 3rem 0 2rem;
`

const PageNavigation: React.SFC<Props> = () => {
  const { data } = useContext(MeContext)
  return (
    <Navigation>
      <NavItem>
        <NavLink to="/account/profile" activeClassName="active">
          Profile
        </NavLink>
      </NavItem>
      {data && data.me.userType === UserTypeEnum.Parent && (
        <>
          <NavItem>
            <NavLink to="/account/billing" activeClassName="active">
              Billing
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/account/sub-accounts" activeClassName="active">
              Sub-Accounts
            </NavLink>
          </NavItem>
        </>
      )}
    </Navigation>
  )
}

PageNavigation.defaultProps = defaultProps

export default PageNavigation
