import React from 'react'
import { Flex, SelectInput } from '@curvo/common-ui'
import { ComparisonEnum } from '@curvo/apollo'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  value: any
  onChange: (option: any) => void
}

const COMPARISON_SIGNS: { label: string; value: ComparisonEnum }[] = [
  {
    value: ComparisonEnum.BiggerThan,
    label: '>',
  },
  {
    value: ComparisonEnum.LessThan,
    label: '<',
  },
  {
    value: ComparisonEnum.BiggerThanOrEqual,
    label: '>=',
  },
  {
    value: ComparisonEnum.LessThanOrEqual,
    label: '<=',
  },
  {
    value: ComparisonEnum.Equal,
    label: '=',
  },
]

const ComparionSignsSelect: React.SFC<Props> = ({ value, onChange }) => (
  <Flex flexDirection="column" flex="2">
    <SelectInput
      label="Sizes"
      options={COMPARISON_SIGNS}
      value={COMPARISON_SIGNS.find(item => item.value === value)}
      onChange={onChange}
    />
  </Flex>
)

ComparionSignsSelect.defaultProps = defaultProps

export default ComparionSignsSelect
