import React from 'react'
import { GroupInput, TextInput, Flex, Button, Alert, SelectInput, Option } from '@curvo/common-ui'
import { FormikProps } from 'formik'
import { Form } from '../../Auth/SignInForm'
import { Lock } from '../../../components/Icons'
import { COUNTRIES } from '../../../helpers/constants'
import { Values } from './BillingAddressModal'

const defaultProps = {
  isAddNew: false,
  total: 0,
}

type Props = Partial<typeof defaultProps> & FormikProps<Values>

const getCountryOptions = () =>
  COUNTRIES.reduce<{
    listCountries: { label: string; value: string }[]
    phonecodes: { [key: string]: string }
  }>(
    (previousResult, currentItem) => ({
      listCountries: [...previousResult.listCountries, { label: currentItem.name, value: currentItem.name }],
      phonecodes: {
        ...previousResult.phonecodes,
        [currentItem.name]: currentItem.code,
      },
    }),
    {
      listCountries: [],
      phonecodes: {},
    },
  )

const BillingAddressForm: React.SFC<Props> = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
  isAddNew,
  total,
  setFieldValue,
  setFieldTouched,
}) => {
  const handleSelectChange = (option?: Option) => {
    if (option) {
      setFieldValue('phoneNumber', getCountryOptions().phonecodes[option.value])
      setFieldTouched('phoneNumber', true)
      setFieldValue('addressCountry', option)
      return
    }
    setFieldValue('phoneNumber', '')
    setFieldTouched('phoneNumber', true)
    setFieldValue('addressCountry', option)
  }
  return (
    <Form onSubmit={handleSubmit}>
      {values.formError && (
        <Alert danger marginBottom="1rem">
          {values.formError}
        </Alert>
      )}
      {values.name && values.name !== ' ' ? (
        <TextInput
          name="name"
          value={values.name}
          onChange={handleChange}
          error={(touched.name && errors.name) || ''}
          placeholder="Your Name"
        />
      ) : (
        <GroupInput>
          <TextInput
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            error={(touched.firstName && errors.firstName) || ''}
            placeholder="First Name"
          />
          <TextInput
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            error={(touched.lastName && errors.lastName) || ''}
            placeholder="Last Name"
          />
        </GroupInput>
      )}
      <TextInput
        name="addressLine1"
        value={values.addressLine1}
        onChange={handleChange}
        error={(touched.addressLine1 && errors.addressLine1) || ''}
        placeholder="Address"
      />
      <TextInput
        name="addressLine2"
        value={values.addressLine2}
        onChange={handleChange}
        error={(touched.addressLine2 && errors.addressLine2) || ''}
        placeholder="Address line 2 (optional)"
      />
      <GroupInput>
        <TextInput
          name="addressCity"
          value={values.addressCity}
          onChange={handleChange}
          error={(touched.addressCity && errors.addressCity) || ''}
          placeholder="City"
        />
        <TextInput
          name="addressState"
          value={values.addressState}
          onChange={handleChange}
          error={(touched.addressState && errors.addressState) || ''}
          placeholder="State / Region"
        />
        <TextInput
          name="addressZip"
          value={values.addressZip}
          onChange={handleChange}
          error={(touched.addressZip && errors.addressZip) || ''}
          placeholder="Post Code"
        />
      </GroupInput>
      <GroupInput>
        <SelectInput
          options={getCountryOptions().listCountries}
          value={values.addressCountry}
          onChange={handleSelectChange}
        />
        <TextInput name="phoneNumber" value={values.phoneNumber} onChange={handleChange} placeholder="Phone Number" />
      </GroupInput>
      <Flex justifyContent={!isAddNew ? 'flex-end' : undefined}>
        <Button block={isAddNew} large type="submit" loading={isSubmitting}>
          {isAddNew ? (
            <React.Fragment>
              <Lock /> Pay ${total ? total / 100 : '0'}
            </React.Fragment>
          ) : (
            'Save change'
          )}
        </Button>
      </Flex>
    </Form>
  )
}

BillingAddressForm.defaultProps = defaultProps

export default BillingAddressForm
