import { User } from '@curvo/apollo'
import { Button, Card, Flex, Gravatar, Modal, NormalText, Toggle } from '@curvo/common-ui'
import React from 'react'
import EditProfileForm from './EditProfileForm'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  data: User
}

const ProfileDetails: React.SFC<Props> = ({ data }) => (
  <Card padding="2rem" flexDirection="row" marginBottom="2rem">
    <Gravatar email={data.email} marginRight="2rem" size="6rem" />
    <Flex flexDirection="column" flex={1}>
      <NormalText size="1.2rem">{`${data.firstName} ${data.lastName} ${
        !data.firstName && !data.lastName ? 'No Name' : ''
      }`}</NormalText>
      <NormalText size="1rem">{`${data.email}`}</NormalText>
    </Flex>
    <Toggle>
      {({ isActive, on, off }) => (
        <React.Fragment>
          <Button primary alignSelf="center" large onClick={on}>
            Edit Profile
          </Button>
          <Modal isOpen={isActive} onRequestToClose={off}>
            <EditProfileForm data={data} onRequestToClose={off} />
          </Modal>
        </React.Fragment>
      )}
    </Toggle>
  </Card>
)

ProfileDetails.defaultProps = defaultProps

export default ProfileDetails
