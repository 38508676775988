import { Colors } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory'

type ChartData = Array<{ x: number; y: number }>

type Props = {
  chartData: ChartData
  color?: string
}

const StyledChartWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const BigChart: React.SFC<Props> = ({ chartData, color }) => (
  <StyledChartWrapper>
    <VictoryChart height={200} padding={{ left: 35, bottom: 35, right: 35 }} domainPadding={{ x: 0, y: 5 }}>
      <VictoryLine
        interpolation="catmullRom"
        data={chartData}
        style={{
          data: { stroke: color, strokeWidth: 1 },
        }}
      />
      <VictoryAxis
        tickCount={6}
        tickFormat={tick => `${new Date(tick).getMonth()}/${new Date(tick).getFullYear()}`}
        style={{
          grid: { stroke: 'none' },
          axis: { stroke: grid },
          tickLabels: {
            fontSize: '8px',
            padding: 5,
            color: grid,
            fontFamily: 'inherit',
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        tickCount={5}
        style={{
          axis: { stroke: grid },
          grid: { stroke: grid },
          tickLabels: {
            fontSize: '8px',
            padding: 5,
            color: grid,
            fontFamily: 'inherit',
          },
        }}
      />
    </VictoryChart>
  </StyledChartWrapper>
)
export const SmallChart: React.SFC<Props> = ({ chartData, color }) => (
  <StyledChartWrapper>
    <VictoryChart height={200} padding={{ left: 35, bottom: 35, right: 35 }} domainPadding={{ x: 0, y: 5 }}>
      <VictoryLine
        interpolation="catmullRom"
        data={chartData}
        style={{
          data: { stroke: color, strokeWidth: 1 },
        }}
      />
      <VictoryAxis tickFormat={() => ''} style={{ axis: { stroke: 'none' } }} />
    </VictoryChart>
  </StyledChartWrapper>
)

BigChart.defaultProps = {
  color: Colors.Primary,
}

// *
// * Colors
// *
const line = Colors.Primary
const grid = '#cccccc'
// *
// * Typography
// *
// const sansSerif = '\'Roboto\', \'Helvetica Neue\', Helvetica, sans-serif'
const sansSerif =
  "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
const letterSpacing = 'normal'
const fontSize = 12
// *
// * Layout
// *
const padding = 0
// const baseProps = {
//   width: '100%',
// }
// const smallProps = {
//   width: '100%',
// }
// *
// * Labels
// *
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  fill: line,
  stroke: 'transparent',
  strokeWidth: 0,
}

const centeredLabelStyles = Object.assign({}, { textAnchor: 'middle' }, baseLabelStyles)
// *
// * Strokes
// *
const strokeDasharray = '10, 5'
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

export const bigGraph = {
  area: {
    data: {
      fill: grid,
    },
    labels: centeredLabelStyles,
  },
  axis: {
    axis: {
      fill: 'transparent',
      stroke: 'none',
      strokeWidth: 0,
      strokeLinecap,
      strokeLinejoin,
    },
    axisLabel: Object.assign({}, centeredLabelStyles, {
      padding,
      stroke: 'transparent',
    }),
    grid: {
      fill: 'none',
      stroke: grid,
      strokeDasharray,
      strokeLinecap,
      strokeLinejoin,
    },
    ticks: {
      fill: 'transparent',
      stroke: 'none',
      strokeWidth: 0,
      strokeLinecap,
      strokeLinejoin,
    },
    tickLabels: Object.assign({}, baseLabelStyles, {
      fill: grid,
    }),
  },
  line: {
    data: {
      fill: Colors.Primary,
      opacity: 1,
      stroke: line,
      strokeWidth: 1,
      width: 1,
    },
    labels: centeredLabelStyles,
  },
}
