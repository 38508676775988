import React from 'react'
import { FormikState, FormikHandlers } from 'formik'
import { TextInput, Button, Alert } from '@curvo/common-ui'
import { Form } from './SignInForm'
import { Values } from './ResetPassword'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & FormikState<Values> & FormikHandlers

const ForgetPasswordForm: React.SFC<Props> = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => (
  <Form onSubmit={handleSubmit}>
    {values.formError && (
      <Alert danger marginBottom="1rem">
        {values.formError}
      </Alert>
    )}
    <TextInput
      name="email"
      placeholder="Enter your Email"
      value={values.email.toLowerCase()}
      onChange={handleChange}
      error={(touched.email && errors.email) || undefined}
    />
    {values.sentCode && (
      <React.Fragment>
        <Alert info marginBottom="1rem">
          A confirm code was sent to your Email!
        </Alert>
        <TextInput
          name="code"
          placeholder="Enter your Code"
          value={values.code}
          onChange={handleChange}
          error={(touched.code && errors.code) || undefined}
        />
        <TextInput
          name="password"
          type="password"
          placeholder="Enter New Password"
          value={values.password}
          onChange={handleChange}
          error={(touched.password && errors.password) || undefined}
        />
        <TextInput
          name="confirmPassword"
          type="password"
          placeholder="Confirm New Password"
          value={values.confirmPassword}
          onChange={handleChange}
          error={(touched.confirmPassword && errors.confirmPassword) || undefined}
        />
      </React.Fragment>
    )}
    <Button primary block large marginBottom="1rem" type="submit" loading={isSubmitting}>
      Reset Password
    </Button>
  </Form>
)

ForgetPasswordForm.defaultProps = defaultProps

export default ForgetPasswordForm
