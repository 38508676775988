import { AvgPriceData, Part, PartImagesData, PricesData } from '@curvo/apollo'
import { Colors, darken, Flex, Modal, NormalText, Toggle, Tooltip } from '@curvo/common-ui'
import { extractFlex, FlexProps } from '@curvo/common-ui/dist/components/Elements/Flex'
import { extractMarginAndPadding } from '@curvo/common-ui/dist/helpers/StyledFunctions'
import React from 'react'
import styled from 'styled-components'
import LoadingIndicator from '../../../components/LoadingIndicator'
import AvgPrices from './AvgPrices'
import { SmallChart } from './Chart'
import Prices from './Prices'
import ResultItemDetails from './ResultItemDetails'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & { data: Part }

const Container = styled(Flex)`
  border-radius: 3px;
  background: #ffffff;
  &:hover {
    box-shadow: 0 2px 8px 0 rgba(89, 99, 109, 0.1);
  }
`

export const ImageWrapper = styled(Flex)`
  width: 170px;
  height: 170px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.Border};
  border-radius: 3px;
  cursor: pointer;
  img {
    width: 100%;
    max-height: 100%;
  }
`

export const Text = styled(NormalText)`
  line-height: normal;
`

export const Row = styled(Flex)`
  border-bottom: 1px solid ${Colors.Border};
  border-top: 1px solid ${Colors.Border};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
`

const PartName = styled(Text)`
  cursor: pointer;
`

export const MetaData: React.SFC<
  {
    heading: string
    content: string
    uppercase?: boolean
  } & FlexProps
> = ({ heading, content, uppercase, ...restProps }) => {
  const [flexProps, notFlexProps] = extractFlex(restProps)
  const { paddings: paddingProps, margins: marginProps } = extractMarginAndPadding(notFlexProps)
  return (
    <Flex flexDirection="column" flex="1" {...flexProps} {...paddingProps} {...marginProps}>
      <Text secondary size="0.625rem" uppercase>
        {heading}
      </Text>
      <Text size="0.75rem" uppercase={uppercase}>
        {content}
      </Text>
    </Flex>
  )
}
MetaData.defaultProps = { uppercase: false }

export function getCreatedDate(createdAt: string) {
  const dateCreatedAt = new Date(createdAt)
  const month = dateCreatedAt.getMonth() + 1
  const date = dateCreatedAt.getDate()
  return ` ${month < 10 ? `0${month}` : month}/${date < 10 ? `0${date}` : date}/${dateCreatedAt.getFullYear()}`
}

export function writeBrandManufacturerName(data: Part) {
  const { manufacturer, brand } = data

  if (manufacturer && manufacturer.name && brand && brand.name) {
    if (manufacturer.name === brand.name) {
      return <Text primary>{`${manufacturer.name} ${manufacturer.id && `(${manufacturer.id})`}`}</Text>
    } else {
      return (
        <Text primary>
          {`${manufacturer.name} ${manufacturer.id && `(${manufacturer.id})`}`}/{brand.name}
        </Text>
      )
    }
  } else if (brand && brand.name) {
    return <Text primary>{brand.name}</Text>
  } else {
    return
  }
}

export function showGICSizeDescription({ gic }: Part) {
  const { sizeOneDescription, sizeTwoDescription, sizeThreeDescription } = gic
  return [sizeOneDescription, sizeTwoDescription, sizeThreeDescription].filter(description => !!description).join(' X ')
}

const ResultItem: React.SFC<Props> = ({ data }) => (
  <Toggle>
    {({ isActive, toggle, on }) => (
      <React.Fragment>
        <Container padding="1rem">
          <ImageWrapper marginRight="1rem" onClick={on}>
            <PartImagesData variables={{ partId: data.id }}>
              {({ data: imageData, loading }) => {
                if (loading || !imageData) {
                  return <LoadingIndicator center />
                }
                return imageData.partImages && imageData.partImages.length ? (
                  <img alt="part" src={imageData.partImages[0]} />
                ) : (
                  <>'No Image'</>
                )
              }}
            </PartImagesData>
          </ImageWrapper>
          <Flex flexDirection="column" flex="1">
            <Flex>
              <Flex flexDirection="column" marginRight="0.5em" justifyContent="center" flex="2">
                <Flex flexWrap="wrap">
                  <PartName size="1rem" bold onClick={on} marginRight="16px">
                    {data.partName}
                  </PartName>
                  {data.createdAt && (
                    <Text secondary>
                      added on
                      {getCreatedDate(data.createdAt)}
                    </Text>
                  )}
                </Flex>
                <Flex alignItems="baseline" flexWrap="wrap" justifyContent="flex-start">
                  {writeBrandManufacturerName(data)}
                  &nbsp;in&nbsp;
                  <Text primary>
                    {data.gic.name}&nbsp;(GIC {data.gic.id})
                  </Text>
                </Flex>
              </Flex>
              <Flex flexDirection="column">
                <Text size="1.75rem" alignSelf="center" flex="1" right>
                  {data.partNumber}
                </Text>

                {data.createdAt && (
                  <Text secondary size="0.625rem" uppercase>
                    Added: {new Date(data.createdAt).toLocaleDateString('en-us')}
                  </Text>
                )}
              </Flex>
            </Flex>
            <Row>
              <MetaData
                heading="Product line"
                content={data.productLine && !!data.productLine.name.length ? data.productLine.name : '-'}
                marginRight="0.5em"
              />
              <Flex flexDirection="column" flex="1">
                <Text secondary size="0.625rem" uppercase>
                  Material
                </Text>
                <Text size="0.75rem">
                  <Tooltip
                    content={data.material ? data.material.name : ''}
                    disable={data.material && data.material.name.length ? false : true}
                    flex={undefined}
                    marginRight="0.5em"
                    highlightedColor={darken(Colors.Secondary, 0.3)}>
                    {data.material && !!data.material.name.length ? data.material.id : '-'}
                  </Tooltip>
                </Text>
              </Flex>
              <Flex flexDirection="column" flex="1">
                <Text secondary size="0.625rem" uppercase>
                  Sizes
                </Text>
                <Text size="0.75rem">
                  <Tooltip
                    content={showGICSizeDescription(data)}
                    disable={
                      data.gic.sizeOneDescription || data.gic.sizeTwoDescription || data.gic.sizeThreeDescription
                        ? false
                        : true
                    }
                    flex={undefined}
                    marginRight="0.5em"
                    highlightedColor={darken(Colors.Secondary, 0.3)}>
                    {data.sizeOne}x{data.sizeTwo}x{data.sizeThree}
                  </Tooltip>
                </Text>
              </Flex>
              <MetaData
                heading="Segment"
                content={data.segmentation && data.segmentation.segment ? data.segmentation.segment.name : '-'}
                marginRight="0.5em"
              />
              <MetaData heading="Type1" content={data.typeOne ? data.typeOne.name : '-'} marginRight="0.5em" />
              <MetaData heading="Type2" content={data.typeTwo ? data.typeTwo.name : '-'} />
            </Row>
            <Flex justifyContent="space-between">
              <PricesData variables={{ partId: data.id }}>
                {({ data: priceData, loading }) => {
                  if (loading || !priceData) {
                    return <LoadingIndicator center />
                  }
                  return <Prices data={priceData.prices} />
                }}
              </PricesData>
              <Flex padding="0 0.5em" />
              <AvgPriceData
                variables={{
                  condition: { partId: data.id },
                }}>
                {({ data: avgData, loading }) => {
                  if (loading || !avgData) {
                    return <LoadingIndicator center />
                  }
                  const items = avgData.averagePrices
                  const chartData = items.map(item => {
                    return {
                      x: +item.periodStartDate,
                      y: item.averagePrice,
                    }
                  })
                  return (
                    <AvgPrices data={avgData.averagePrices}>
                      <SmallChart chartData={chartData} color={Colors.Primary} />
                    </AvgPrices>
                  )
                }}
              </AvgPriceData>
            </Flex>
          </Flex>
        </Container>
        <Modal isOpen={isActive} onRequestToClose={toggle} blackOverlay>
          <ResultItemDetails data={data} handleClose={toggle} />
        </Modal>
      </React.Fragment>
    )}
  </Toggle>
)

ResultItem.defaultProps = defaultProps

export default ResultItem
