import { CountFilterData, CountFiltersQueryArgs } from '@curvo/apollo'
import { Button, Colors, Flex, NormalText, Option } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import { CheckedIcon } from '../../../components/Icons'
import LoadingIndicator from '../../../components/LoadingIndicator'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  onManufacturerChange: (value: Option) => void
  onGicChange: (value: Option) => void
  selectedGic: any
  selectedManufacturer: any
  variables: CountFiltersQueryArgs
}

const Container = styled.div`
  width: 240px;
  min-width: 240px;
  margin-right: 1rem;
`

const GroupContainer = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${Colors.Border};
  position: relative;
`

const ItemContainer = styled(Flex)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  align-items: center;
`

const ItemName = styled(NormalText)`
  max-width: 160px;
  flex-wrap: wrap;
  margin-right: auto;
`

const ItemCount = styled(NormalText)`
  margin-left: 16px;
`

const LoadingContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: center;
  align-items: center;
`

const GroupData: React.SFC<{
  label: string
  data?: any[]
  loading: boolean
  onItemClick: (value: Option) => void
  fetchMore?: FuncVoid
  currentValue?: any
}> = ({ label, data, loading, fetchMore, currentValue, onItemClick }) => (
  <GroupContainer>
    <NormalText medium size="0.875rem" marginBottom="0.5rem">
      {label}
    </NormalText>
    {loading && (
      <LoadingContainer>
        <LoadingIndicator center />
      </LoadingContainer>
    )}
    {data &&
      !!data.length &&
      data.map(({ name, partsCount, id }) => (
        <ItemContainer key={id} onClick={() => onItemClick({ label: name, value: id })}>
          {<ItemName>{name}</ItemName>}
          {currentValue && currentValue.value === id ? (
            <CheckedIcon />
          ) : (
            <ItemCount secondary>{new Intl.NumberFormat('en-US').format(partsCount)}</ItemCount>
          )}
        </ItemContainer>
      ))}
    {data && !data.length && <ItemContainer>No Data</ItemContainer>}
    {fetchMore && (
      <Button link onClick={fetchMore}>
        Show 10+ more
      </Button>
    )}
  </GroupContainer>
)

const BasicSidebar: React.SFC<Props> = ({
  onGicChange,
  onManufacturerChange,
  selectedGic,
  selectedManufacturer,
  variables,
}) => (
  <CountFilterData variables={variables}>
    {({ data, loading }) => (
      <Container>
        <GroupData
          label="Manufacturer"
          data={(data && data.countFilters && data.countFilters.manufacturer) || undefined}
          loading={loading}
          onItemClick={onManufacturerChange}
          currentValue={selectedManufacturer}
        />
        <GroupData
          label="GICs"
          data={(data && data.countFilters && data.countFilters.gics) || undefined}
          loading={loading}
          onItemClick={onGicChange}
          currentValue={selectedGic}
        />
      </Container>
    )}
  </CountFilterData>
)

BasicSidebar.defaultProps = defaultProps

export default BasicSidebar
