import { Container } from '@curvo/common-ui'
import React from 'react'
import { Redirect, Switch } from 'react-router'
import AuthorizedRoute from '../../components/AuthorizedRoute'
import Header from '../../components/Header'
import Layout from '../../components/Layout'
import BillingInfo from './BillingInfo'
import Subscription from './Subscription'
const defaultProps = {}

type Props = typeof defaultProps & {}

const Setup: React.SFC<Props> = () => {
  return (
    <Layout header={<Header title="Setup" />}>
      <Container>
        <Switch>
          <AuthorizedRoute path="/setup/billing-info" component={BillingInfo} />
          <AuthorizedRoute path="/setup/subscription" component={Subscription} />
          <Redirect to="/setup" />
        </Switch>
      </Container>
    </Layout>
  )
}

Setup.defaultProps = defaultProps

export default Setup
