import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('/fonts/NeurialGrotesk-Light.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('/fonts/NeurialGrotesk-BoldItalic.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('/fonts/NeurialGrotesk-Regular.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('/fonts/NeurialGrotesk-Bold.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neurial Grotesk Exbold';
    src: url('/fonts/NeurialGrotesk-ExtraboldItalic.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-ExtraboldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('/fonts/NeurialGrotesk-RegularItalic.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('/fonts/NeurialGrotesk-MediumItalic.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('/fonts/NeurialGrotesk-Medium.woff2') format('woff2'),
      url('/fonts/NeurialGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('../fonts/NeurialGrotesk-LightItalic.woff2') format('woff2'),
      url('../fonts/NeurialGrotesk-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neurial Grotesk';
    src: url('../fonts/NeurialGrotesk-Extrabold.woff2') format('woff2'),
      url('../fonts/NeurialGrotesk-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  body, html, body * {
    font-family: 'Neurial Grotesk';
    font-weight: normal;
  }
`

export default GlobalStyles
