import { MeConsumer, SearchData } from '@curvo/apollo'
import { Button, Colors, Flex, Grid, Option, Pagination } from '@curvo/common-ui'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import Helmet from 'react-helmet'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../../components/Header'
import { SearchIcon } from '../../components/Icons'
import Layout from '../../components/Layout'
import LoadingIndicator from '../../components/LoadingIndicator'
import AdvanceForm from './components/AdvanceForm'
import { emptyValues, getSearchParams, Values } from './components/AdvanceMode'
import BasicSidebar from './components/BasicSidebar'
import ChangeModeButton from './components/ChangeModeButton'
import Footer from './components/Footer'
import HeaderSearchInput from './components/HeaderSearchInput'
import ResultItem from './components/ResultItem'
import { SearchMode } from './Homepage'

const REACHED_LIMIT = "You've reached subscription limit. Please, renew your subscription to continue."

const defaultProps = {}

const initialState = {
  showFilter: false,
  currentPage: 1,
}

type Props = Partial<typeof defaultProps> &
  RouteComponentProps<any, any, { searchMode: SearchMode; searchText: string }> & {}

type State = Readonly<
  typeof initialState & {
    searchMode: SearchMode
    searchText?: string
    showFilter: boolean
  } & Partial<Values>
>

const SidebarAdvance = styled.div`
  min-width: 700px;
  width: 700px;
  margin-right: 1rem;
`

const ITEMS_PER_PAGE = 10

const DataContainer = styled.div<{ loading?: boolean }>`
  position: relative;
  width: 100%;
  ${props =>
    props.loading &&
    `
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.7);
      z-index: 1;
    }
  `}
`

const LoadingContainer = styled(Flex)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  width: 69px;
  height: 69px;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 50%;
`

const StyledGrid = styled(Grid)`
  @media screen and (max-width: 1440px) {
    grid-template-columns: 1fr;
  }
`

const LimitMessage = styled(Flex)`
  align-self: center;
  a {
    /* padding-left: 5px;
    padding-right: 3px; */
    color: ${Colors.Primary};
  }
`

export default class SearchResult extends React.Component<Props, State> {
  static defaultProps = defaultProps

  constructor(props: Props) {
    super(props)
    this.state = {
      ...props.location.state,
      ...initialState,
    }
  }

  private toggleFilter = () => {
    const { showFilter } = this.state
    this.setState({ showFilter: !showFilter })
  }

  private handleChangeSearchMode = (): void => {
    const { searchMode } = this.state
    this.setState({
      searchMode: searchMode === SearchMode.Basic ? SearchMode.Advance : SearchMode.Basic,
      showFilter: true,
      searchText: undefined,
    })
    this.handleResetAdvanceSearch()
  }

  private handleSubmitAdvanceSearch = (values: Values) => this.setState({ ...emptyValues, ...values, currentPage: 1 })

  private handleSubmitBasicSearch = (values: { searchText?: string }) =>
    this.setState({ ...emptyValues, ...values, currentPage: 1 })

  private handleResetAdvanceSearch = () => this.setState(emptyValues)

  private renderHeader = () => {
    const { searchMode, searchText, showFilter } = this.state
    if (searchMode === SearchMode.Basic) {
      const initValue = searchText ? { searchText } : undefined
      return (
        <Flex>
          <HeaderSearchInput initialValues={initValue} onSubmit={this.handleSubmitBasicSearch} />
        </Flex>
      )
    }
    return (
      <Button onClick={this.toggleFilter}>
        <SearchIcon /> {showFilter ? 'Hide' : 'Show'} Filter
      </Button>
    )
  }

  private getFormInitialValues = () => {
    const {
      manufacturer,
      gic,
      typeOne,
      typeTwo,
      material,
      segmentation,
      brand,
      productLine,
      addAfter,
      comparisonSign,
      sizeOne,
      sizeTwo,
      sizeThree,
      partialPartNumber,
      partialPartDesc,
    } = this.state
    return {
      manufacturer: manufacturer ? manufacturer : undefined,
      gic: gic ? gic : undefined,
      typeOne: typeOne ? typeOne : undefined,
      typeTwo: typeTwo ? typeTwo : undefined,
      material: material ? material : undefined,
      segmentation: segmentation ? segmentation : undefined,
      brand: brand ? brand : undefined,
      productLine: productLine ? productLine : undefined,
      addAfter: addAfter || '',
      comparisonSign: comparisonSign || '',
      sizeOne: sizeOne || '',
      sizeTwo: sizeTwo || '',
      sizeThree: sizeThree || '',
      partialPartNumber: partialPartNumber || '',
      partialPartDesc: partialPartDesc || '',
    }
  }

  private handleManufacturerChange = (value: Option) => {
    const { manufacturer } = this.state
    if (manufacturer && manufacturer.value === value.value) {
      this.setState({ manufacturer: undefined, currentPage: 1 })
      return
    }
    this.setState({ manufacturer: value, currentPage: 1 })
  }

  private handleGicChange = (value: Option) => {
    const { gic } = this.state
    if (gic && gic.value === value.value) {
      this.setState({ gic: undefined, currentPage: 1 })
      return
    }
    this.setState({ gic: value, currentPage: 1 })
  }

  public componentDidMount() {
    const { location, history } = this.props
    if (!location.state) {
      history.push('/')
    }
  }

  render() {
    const { searchMode, searchText: rawSearchText, showFilter, currentPage } = this.state
    const searchText = rawSearchText && rawSearchText.replace(/[^a-zA-Z0-9]/g, '')
    const isBasicMode = searchMode === SearchMode.Basic
    const variables = getSearchParams(this.state)

    return (
      <MeConsumer>
        {({ refetchData }) => (
          <Layout
            header={
              <Header marginBottom="1rem" showName={false}>
                <ChangeModeButton value={searchMode} onChange={this.handleChangeSearchMode} />
                {this.renderHeader()}
              </Header>
            }
            showFooter
            footer={Footer}>
            <Helmet>
              <title>Search Result | ONN (by Curvo)</title>
            </Helmet>
            <Flex padding="2rem" flex="1 0 auto">
              {isBasicMode && (
                <BasicSidebar
                  onManufacturerChange={this.handleManufacturerChange}
                  onGicChange={this.handleGicChange}
                  selectedGic={this.state.gic}
                  selectedManufacturer={this.state.manufacturer}
                  variables={{
                    searchText,
                    ...variables,
                  }}
                />
              )}
              {!isBasicMode && showFilter && (
                <Formik
                  initialValues={this.getFormInitialValues()}
                  onSubmit={this.handleSubmitAdvanceSearch}
                  render={(props: FormikProps<Values>) => {
                    const { resetForm, submitForm } = props
                    const handleResetForm = () => {
                      resetForm(emptyValues)
                      this.handleResetAdvanceSearch()
                    }

                    return (
                      <SidebarAdvance>
                        <AdvanceForm {...props} />
                        <Flex justifyContent="flex-end">
                          <Button ghost onClick={handleResetForm}>
                            Clear
                          </Button>
                          <Button onClick={submitForm}>Update Result</Button>
                        </Flex>
                      </SidebarAdvance>
                    )
                  }}
                />
              )}
              <SearchData
                variables={{
                  searchText: (searchText || '').replace('/', '//'),
                  offset: (currentPage - 1) * ITEMS_PER_PAGE,
                  limit: ITEMS_PER_PAGE,
                  ...variables,
                }}
                fetchPolicy="network-only"
                selfHandleError>
                {({ data, loading, error }) => {
                  if (refetchData) {
                    refetchData()
                  }
                  const resultItems = data?.search?.data || []
                  return (
                    <DataContainer loading={loading}>
                      {loading && (
                        <LoadingContainer>
                          <LoadingIndicator center size={30} />
                        </LoadingContainer>
                      )}
                      {!!error && error.message && error.message.includes(REACHED_LIMIT) && (
                        <LimitMessage>
                          You've reached subscription limit. Please, renew&nbsp;
                          <Link to="/setup/subscription">your subscription</Link>
                          &nbsp;to continue.
                        </LimitMessage>
                      )}

                      <StyledGrid
                        gridTemplateColumns={`repeat(${isBasicMode || (!isBasicMode && !showFilter) ? '2' : '1'}, 1fr)`}
                        gridAutoRows="min-content"
                        gridGap="1.6rem"
                        block>
                        {resultItems.length > 0 ? (
                          resultItems.map(part => <ResultItem key={part.id} data={part} />)
                        ) : (
                          <h2>No Results - 0 parts found</h2>
                        )}
                      </StyledGrid>

                      {resultItems.length > 0 && (
                        <Pagination
                          marginTop="1rem"
                          total={data && data.search ? Math.ceil(data.search.total / ITEMS_PER_PAGE) : 1}
                          page={currentPage}
                          onGoTo={goToPage => {
                            this.setState({ currentPage: goToPage })
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                          }}
                        />
                      )}
                    </DataContainer>
                  )
                }}
              </SearchData>
            </Flex>
          </Layout>
        )}
      </MeConsumer>
    )
  }
}
