import { updateMe, User } from '@curvo/apollo'
import { Alert, Button, CheckboxInput, Flex, GroupInput, NormalText, Notify, TextInput } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { Form } from '../../Auth/SignInForm'
import { Container } from './ChangePasswordForm'

const defaultProps = {}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  company: '',
  addressLineOne: '',
  addressLineTwo: '',
  state: '',
  city: '',
  zip: '',
  wantHardCopy: false,
}

type Props = typeof defaultProps & {
  data: User
  onRequestToClose: FuncVoid
}

type Values = typeof initialValues & {
  formError?: string
}

const validationSchema = () => {
  const shape = {
    email: Yup.string().email('Email is not valid!'),
  }
  return Yup.object().shape(shape)
}

const transformData = (data: User): Values => ({
  firstName: data.firstName || '',
  lastName: data.lastName || '',
  email: data.email,
  phoneNumber: data.phoneNumber || '',
  company: data.company || '',
  addressLineOne: data.addressLineOne || '',
  addressLineTwo: data.addressLineTwo || '',
  state: data.state || '',
  zip: data.zip || '',
  city: data.city || '',
  wantHardCopy: data.wantHardCopy || false,
})

const onSubmit =
  (onRequestToClose: FuncVoid) =>
  async ({ formError: _formError, ...userData }: Values, { setSubmitting, setFieldValue }: FormikActions<Values>) => {
    try {
      await updateMe(userData)
      Notify.primary('Edited profile successful!')
      setSubmitting(false)
      onRequestToClose()
    } catch (error) {
      setFieldValue('formError', error.message || 'Something went wrong!')
      setSubmitting(false)
    }
  }

const EditProfileForm: React.SFC<Props> = ({ data, onRequestToClose }) => {
  const handleEditProfile = onSubmit(onRequestToClose)
  return (
    <Container>
      <NormalText size="1.25rem" marginBottom="2rem">
        Edit Profile
      </NormalText>
      <Formik
        initialValues={data ? transformData(data) : initialValues}
        onSubmit={handleEditProfile}
        validationSchema={validationSchema}>
        {({ values, touched, errors, handleSubmit, handleChange, setFieldValue, handleBlur, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {values.formError && (
              <Alert danger marginBottom="1rem">
                {values.formError}
              </Alert>
            )}
            <NormalText medium size="16px" marginBottom="0.5rem">
              Your Profile
            </NormalText>
            <GroupInput>
              <TextInput
                name="firstName"
                value={values.firstName}
                placeholder="First Name"
                onChange={handleChange}
                error={touched.firstName ? errors.firstName : undefined}
              />
              <TextInput
                name="lastName"
                value={values.lastName}
                placeholder="Last Name"
                onChange={handleChange}
                error={touched.lastName ? errors.lastName : undefined}
              />
            </GroupInput>
            <TextInput
              name="email"
              value={values.email}
              placeholder="Email"
              onChange={handleChange}
              error={touched.email ? errors.email : undefined}
              disabled
            />
            <TextInput
              name="phoneNumber"
              value={values.phoneNumber}
              placeholder="Phone number"
              onChange={handleChange}
              error={touched.phoneNumber ? errors.phoneNumber : undefined}
            />
            <TextInput
              name="company"
              value={values.company}
              placeholder="Company"
              onChange={handleChange}
              error={touched.company ? errors.company : undefined}
            />
            <NormalText medium size="16px" marginBottom="0.5rem">
              Shipping Address
            </NormalText>
            <TextInput
              name="addressLineOne"
              value={values.addressLineOne}
              placeholder="Address Line 1"
              onChange={handleChange}
              error={touched.addressLineOne ? errors.addressLineOne : undefined}
            />
            <TextInput
              name="addressLineTwo"
              value={values.addressLineTwo}
              placeholder="Address Line 2"
              onChange={handleChange}
              error={touched.addressLineTwo ? errors.addressLineTwo : undefined}
            />
            <TextInput
              name="city"
              value={values.city}
              placeholder="City"
              onChange={handleChange}
              error={touched.city ? errors.city : undefined}
            />

            <TextInput
              name="state"
              value={values.state}
              placeholder="State / Province / Region"
              onChange={handleChange}
              error={touched.state ? errors.state : undefined}
            />
            <TextInput
              name="zip"
              value={values.zip}
              placeholder="ZIP / Postal Code"
              onChange={handleChange}
              error={touched.zip ? errors.zip : undefined}
            />
            <CheckboxInput
              name="wantHardCopy"
              label="I want a hard copy of newsletter"
              checked={values.wantHardCopy}
              onChange={e => setFieldValue('wantHardCopy', e.target.checked)}
              onBlur={handleBlur}
            />

            <Flex justifyContent="flex-end">
              <Button primary large type="submit" loading={isSubmitting}>
                Update Profile
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

EditProfileForm.defaultProps = defaultProps

export default EditProfileForm
