import { Price } from '@curvo/apollo'
import { Colors, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
// import { ArrowIcon } from '../../../components/Icons'
import styled from 'styled-components'

const defaultProps = {}

const initialState = {}

type Props = Partial<typeof defaultProps> & {
  data?: Price[] | null
}

type PricesType = {
  [key: number]: Price
}

type State = Readonly<
  typeof initialState & {
    selectedYear?: number
    prices?: PricesType
  }
>

const Text = styled(NormalText)`
  line-height: normal;
`

const YearLabel = styled(Text)<{ isDisabled?: boolean }>`
  ${props => (props.isDisabled ? 'text-decoration: line-through;' : undefined)}
  ${props =>
    !props.isDisabled
      ? `
  cursor: pointer;
  &:hover {
    color: ${Colors.Primary};
  }`
      : undefined}
`

const transformPriceData = (data: any[] | null | undefined): PricesType | undefined => {
  if (data) {
    const [minYear, maxYear] = data.reduce(
      ([prevMinYear, prevMaxYear], currentItem) => {
        const currentYear = +currentItem.periodType
        if (currentYear) {
          let newMinYear = prevMinYear
          let newMaxYear = prevMaxYear
          if (newMinYear === 0 || (newMinYear !== 0 && newMinYear > currentYear)) {
            newMinYear = currentYear
          }
          if (newMaxYear === 0 || (newMaxYear !== 0 && newMaxYear < currentYear)) {
            newMaxYear = currentYear
          }
          return [newMinYear, newMaxYear]
        }
        return [prevMinYear, prevMaxYear]
      },
      [0, 0] as number[],
    )

    const yearInRange = Array.from({ length: maxYear - minYear + 1 }).map((_, yearIndex) => maxYear - yearIndex)

    return yearInRange.reduce(
      (previousResult, currentItem) => ({
        ...previousResult,
        [currentItem]: data.filter(price => currentItem === +price.periodType && price.listPrice !== 0),
      }),
      {},
    )
  }
  return undefined
}

export const numberFormat = data => new Intl.NumberFormat('en-US').format(data)

const PriceTag: React.SFC<{ price: number }> = ({ price, children }) => {
  const [value, float] = price.toString().split('.')
  let extendFloat = float
  if (float && float.length === 1) {
    extendFloat += '0'
  }
  return (
    <Flex alignItems="flex-start" marginRight="1rem">
      <Text size="2.25rem">${numberFormat(value)}</Text>
      <Text marginTop="0.625rem" marginLeft="0.375rem" secondary>
        {float ? extendFloat : '00'}
      </Text>
      {children}
    </Flex>
  )
}

export const deduplicateByKey = (array: any[], key: string) =>
  array.reduce((previousResult, currentItem) => {
    const isValueExists = previousResult.find(item => item[key] === currentItem[key])
    if (isValueExists) {
      return previousResult
    }
    return [...previousResult, currentItem]
  }, [])

export default class Prices extends React.Component<Props, State> {
  static defaultProps = defaultProps

  constructor(props: Props) {
    super(props)
    const prices = transformPriceData(props.data)
    const selectedYear = prices
      ? Object.keys(prices).reduce((previousResult, item) => {
          if (prices[item].length) {
            return +item
          }
          return previousResult
        }, 0)
      : undefined
    this.state = {
      selectedYear,
      prices,
    }
  }

  private handleChangeYear = (year: number) => this.setState({ selectedYear: year })

  render() {
    const { prices, selectedYear } = this.state
    if (!prices || !selectedYear) {
      return (
        <Flex flexDirection="column" marginTop="16px" flex={1}>
          <Text uppercase secondary>
            List Price
          </Text>
          <Text size="2.25rem">-</Text>
        </Flex>
      )
    }
    const years = prices ? Object.keys(prices).sort((a, b) => (+a > +b ? -1 : 1)) : []
    return (
      <Flex flexDirection="column" marginTop="16px" flex={1}>
        <Flex flexDirection="column">
          <Text uppercase secondary>
            List Price
          </Text>
          <PriceTag price={prices[selectedYear][0].listPrice} />
          {/* <Flex alignItems="center">
            <ArrowIcon />
            <Text danger marginLeft="0.5rem">
              -15%
            </Text>
          </Flex> */}
        </Flex>
        <Flex flexWrap="wrap">
          {!!years.length &&
            years.map((year, yearIndex) => {
              const disabled = prices[year] && prices[year].length ? false : true
              const handleYearClick = (event: React.MouseEvent<any>) => {
                event.stopPropagation()
                if (!disabled) {
                  this.handleChangeYear(+year)
                }
              }
              return (
                <YearLabel
                  marginRight={yearIndex + 1 !== years.length ? '1rem' : undefined}
                  key={year}
                  isDisabled={disabled}
                  secondary={+year !== +selectedYear || disabled}
                  onClick={handleYearClick}>
                  {year}
                </YearLabel>
              )
            })}
        </Flex>
      </Flex>
    )
  }
}
