import React from 'react'
import { Flex, NormalText, TextInput, Colors, Button, Notify, Alert } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { User } from '@curvo/apollo'
import { Form } from '../../Auth/SignInForm'
import Cognito from '../../../configs/Cognito'

const defaultProps = {}

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

type Props = typeof defaultProps & {
  onRequestToClose: FuncVoid
  data: User
}

type Values = typeof initialValues & {
  formError?: string
}

export const Container = styled(Flex)`
  flex-direction: column;
  padding: 2rem;
  background: ${Colors.White};
  border: 1px solid ${Colors.Border};
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  min-width: 600px;
`

const onSubmit =
  (username: string, onRequestToClose: FuncVoid) =>
  async (values: Values, { setSubmitting, setFieldValue }: FormikActions<Values>) => {
    try {
      await Cognito.changePassword(username, values.oldPassword, values.newPassword)
      Notify.primary('Changed password successful!')
      onRequestToClose()
      setSubmitting(false)
    } catch (error) {
      setFieldValue('formError', error.message || 'Something went wrong!')
      setSubmitting(false)
    }
  }

const validateSchema = () => {
  const shape = {
    oldPassword: Yup.string()
      .min(6, 'Minimum length of 6')
      .max(256, ' Maximum length of 256')
      .required('Old Password is required'),
    newPassword: Yup.string()
      .min(6, 'Minimum length of 6')
      .max(256, ' Maximum length of 256')
      .required('New Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Confirm Password is not match')
      .min(6, 'Minimum length of 6')
      .max(256, ' Maximum length of 256')
      .required('Confirm Password is required'),
  }
  return Yup.object().shape(shape)
}

const ChangePasswordForm: React.SFC<Props> = ({ data, onRequestToClose }) => {
  const handleChangePassword = onSubmit(data.email, onRequestToClose)
  return (
    <Container>
      <NormalText size="1.25rem" marginBottom="2rem">
        Change Password
      </NormalText>
      <Formik initialValues={initialValues} onSubmit={handleChangePassword} validationSchema={validateSchema}>
        {({ values, errors, touched, handleSubmit, handleChange, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            {values.formError && (
              <Alert danger marginBottom="1rem">
                {values.formError}
              </Alert>
            )}
            <TextInput
              name="oldPassword"
              type="password"
              placeholder="Enter Old Password"
              value={values.oldPassword}
              onChange={handleChange}
              error={touched.oldPassword ? errors.oldPassword : undefined}
            />
            <TextInput
              name="newPassword"
              type="password"
              placeholder="Enter New Password"
              value={values.newPassword}
              onChange={handleChange}
              error={touched.newPassword ? errors.newPassword : undefined}
            />
            <TextInput
              name="confirmPassword"
              type="password"
              placeholder="Enter New Password"
              value={values.confirmPassword}
              onChange={handleChange}
              error={touched.confirmPassword ? errors.confirmPassword : undefined}
            />
            <Flex>
              <Button link small>
                Forget Password ?
              </Button>
            </Flex>
            <Flex justifyContent="flex-end">
              <Button primary large loading={isSubmitting} type="submit">
                Change Password
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

ChangePasswordForm.defaultProps = defaultProps

export default ChangePasswordForm
