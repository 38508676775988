import { confirmSignUpUser } from '@curvo/apollo'
import { Button, Card, NormalText, Notify, TextInput } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import React from 'react'
import Helmet from 'react-helmet'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import EmailIcon from '../../assets/images/verify-email.svg'
import Cognito from '../../configs/Cognito'
import useAuthorizedRoute from './hooks/useAuthorizedRoute'
import { Form } from './SignInForm'

const defaultProps = {}

const initialValues = {
  code: '',
  editUsername: false,
  username: '',
}

type Props = typeof defaultProps & RouteComponentProps<any, any, { username: string }> & {}

export type Values = typeof initialValues & {
  formError?: { [key: string]: any }
}

const validationSchema = () => {
  const shape = {
    code: Yup.string().min(6, 'Code is not valid!').max(6, 'Code is not valid!').required('Code is required!'),
    editUsername: Yup.boolean(),
    username: Yup.mixed().when('editUsername', {
      is: true,
      then: Yup.string().required('Email is required!'),
    }),
  }
  return Yup.object().shape(shape)
}

const Icon = styled('img')`
  margin-top: 3rem;
  margin-bottom: 3rem;
`

const VerifyCode: React.SFC<Props> = ({ history, location }) => {
  const loading = useAuthorizedRoute(history)

  if (!location.state) {
    history.push('/auth')
    return null
  }
  const onSubmit = async (values: Values, { setSubmitting, setFieldError }: FormikActions<Values>) => {
    try {
      await confirmSignUpUser({
        code: values.code,
        username: values.username,
      })
      setSubmitting(false)
      Notify.primary('Your account got verified!')
      history.push('/setup/subscription')
    } catch (error) {
      setSubmitting(false)
      if (error.code === 'CodeMismatchException') {
        setFieldError('code', 'Code is not valid!')
        return
      }
      Notify.danger(error.message || "Can't verify account! Try again later!")
    }
  }
  const initValues = location.state ? { ...initialValues, username: location.state.username } : initialValues
  return loading ? null : (
    <React.Fragment>
      <Helmet>
        <title>Verfiy Account | ONN (by Curvo)</title>
      </Helmet>
      <Card padding="3rem" marginTop="4rem">
        <NormalText size="2rem" center marginBottom="2rem">
          Verify Account
        </NormalText>
        <NormalText center>Thanks for signing up for ONN! Please verify your account</NormalText>
        <Icon src={EmailIcon} />
        <Formik initialValues={initValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldTouched, isSubmitting }) => {
            const handleResendCode = async () => {
              if (!values.username && values.editUsername) {
                setFieldTouched('username', true)
                return
              }
              const username = values.editUsername ? values.username : location.state.username
              try {
                await Cognito.resendConfirmationCode(username)
                Notify.primary('A code was sent!')
              } catch (error) {
                if (error.code === 'UserNotFoundException') {
                  Notify.danger('User not found!')
                  return
                }
                Notify.danger(error.message || "Can't resend code! Try again later!")
              }
            }
            return (
              <Form onSubmit={handleSubmit}>
                <NormalText center marginBottom="1rem">
                  A verification code has sent to your email.
                </NormalText>
                {values.editUsername && (
                  <TextInput
                    name="username"
                    value={values.username}
                    placeholder="Username"
                    onChange={handleChange}
                    error={touched.username ? errors.username : undefined}
                  />
                )}
                <TextInput
                  name="code"
                  value={values.code}
                  placeholder="Verification Code"
                  onChange={handleChange}
                  error={touched.code ? errors.code : undefined}
                />
                <Button primary block large loading={isSubmitting} type="submit">
                  Verify Account
                </Button>
                <NormalText size="0.75rem" center marginTop="1rem">
                  Not receive code?{' '}
                  <Button link small onClick={handleResendCode}>
                    Resend code
                  </Button>
                </NormalText>
                <NormalText size="0.75rem" center marginTop="1rem">
                  Not your account?{' '}
                  <Button link small onClick={() => setFieldValue('editUsername', !values.editUsername)}>
                    Change Username
                  </Button>
                </NormalText>
              </Form>
            )
          }}
        </Formik>
      </Card>
    </React.Fragment>
  )
}

VerifyCode.defaultProps = defaultProps

export default VerifyCode
