import { History } from 'history'
import { useEffect, useState } from 'react'
import Cognito from '../../../configs/Cognito'

export default (history: History) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    Cognito.getSession()
      .then(session => {
        if (session.isValid() && session.getAccessToken !== undefined) {
          history.push({
            pathname: '/',
          })
        } else {
          setLoading(false)
        }
      })
      .catch(error => {
        console.info(error)
        setLoading(false)
      })
  }, [history])
  return loading
}
