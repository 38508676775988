import { MeContext, StripeProductEnum } from '@curvo/apollo'
import { Flex, NormalText } from '@curvo/common-ui'
import React, { useContext, useEffect, useState } from 'react'
import { Route, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Cognito from '../configs/Cognito'
import env from '../configs/env'
import LoadingIndicator from './LoadingIndicator'

const Container = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

type Props = RouteProps &
  RouteComponentProps & {
    bypassPaymentCheck?: boolean
    bypassSubscriptionCheck?: boolean
  }

function useAuth({ history, location }: Pick<Props, 'history' | 'location'>) {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      setLoading(false)
      return
    }
    Cognito.getSession()
      .then(session => {
        if (!session.getAccessToken().getJwtToken()) {
          throw new Error('Session invalid')
        }
        setLoading(false)
      })
      .catch(err => {
        console.info(err)
        history.push({
          pathname: '/auth/signin',
          state: { from: location.pathname },
        })
        setLoading(true)
      })
  }, [history, location.pathname])
  return loading
}

function useSetupWizard(
  authChecking: boolean,
  { history }: Pick<Props, 'history' | 'bypassPaymentCheck' | 'bypassSubscriptionCheck'>,
) {
  const meContext = useContext(MeContext)
  const me = meContext.data && meContext.data.me
  const hasPaymentInfo = (me && me.expirationDate) || (me && me.hasPaymentInfo)
  const hasSubscription = (me && me.expirationDate) || (me && me.hasSubscription)

  const [loading, setLoading] = React.useState(true)
  useEffect(() => {
    if (!me || authChecking) {
      return
    }

    const pathname = history.location.pathname
    const BYPASS_PAYMENT_CHECK = ['/setup/billing-info', '/setup/subscription', '/account/profile', '/account/billing']
    const BYPASS_SUBSCRIPTION_CHECK = ['/account/sub-accounts']
    const BYPASS_FINDAPART = '/download/newsletter'

    if (!BYPASS_PAYMENT_CHECK.includes(pathname)) {
      if (hasSubscription === false && hasPaymentInfo === false) {
        history.push('/setup/subscription')
        return
      }
      if (!BYPASS_SUBSCRIPTION_CHECK.includes(pathname)) {
        if (hasSubscription === false) {
          history.push('/setup/subscription')
          return
        }
        const hasFindAPart =
          me.fap ||
          me.subscriptions.find(item => {
            if (item.plan) {
              return item.plan.productType === StripeProductEnum.FindAPart
            }
            return false
          })
        if (!hasFindAPart && !pathname.includes(BYPASS_FINDAPART)) {
          history.push('/account/profile')
          return
        }
      }
    }

    setLoading(false)
  }, [authChecking, hasPaymentInfo, hasSubscription, history, me])
  return authChecking || loading
}

const renderLoading = ({ path }: Props) => (
  <Container>
    {env.env === 'DEV' && <NormalText>{`Path: ${path}`}</NormalText>}
    <LoadingIndicator />
  </Container>
)

const AuthorizedRoute: React.FC<Props> = ({ component, ...restProps }) => {
  const authChecking = useAuth(restProps)
  const setupChecking = useSetupWizard(authChecking, restProps)
  return <Route {...restProps} component={setupChecking ? () => renderLoading(restProps) : component} />
}

export default withRouter(AuthorizedRoute)
