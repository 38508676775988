import { Client } from '@curvo/apollo'
import Cognito from './Cognito'

Client.configClient({
  protocol: 'https',
  wsProtocol: 'wss',
  getToken: async () => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      return token
    }
    const session = await Cognito.getSession()
    return session.getAccessToken().getJwtToken()
  },
})

export default Client
