import { ComparisonEnum, CountResultData } from '@curvo/apollo'
import { Button, Flex } from '@curvo/common-ui'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { SearchMode } from '../Homepage'
import AdvanceForm from './AdvanceForm'
import { numberFormat } from './Prices'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & RouteComponentProps & {}

const LOCAL_STORAGE_KEY = 'advanceSearchParams'

const initialValues = {
  addAfter: '',
  comparisonSign: '',
  sizeOne: '',
  sizeTwo: '',
  sizeThree: '',
  partialPartNumber: '',
  partialPartDesc: '',
}

export type Values = typeof initialValues & {
  manufacturer?: any
  gic?: any
  typeOne?: any
  typeTwo?: any
  material?: any
  segmentation?: any
  brand?: any
  productLine?: any
}

export const emptyValues: Values = {
  ...initialValues,
  manufacturer: undefined,
  gic: undefined,
  typeOne: undefined,
  typeTwo: undefined,
  material: undefined,
  segmentation: undefined,
  brand: undefined,
  productLine: undefined,
}

export const getSearchParams = ({
  manufacturer,
  gic,
  typeOne,
  typeTwo,
  material,
  segmentation,
  brand,
  productLine,
  addAfter,
  comparisonSign,
  sizeOne,
  sizeTwo,
  sizeThree,
  partialPartNumber,
  partialPartDesc,
}: Partial<Values>) => {
  const args = {
    manufacturer: manufacturer ? manufacturer.value : undefined,
    gic: gic ? gic.value : undefined,
    typeOne: typeOne ? typeOne.value : undefined,
    typeTwo: typeTwo ? typeTwo.value : undefined,
    material: material ? material.value : undefined,
    segmentation: segmentation ? segmentation.value : undefined,
    brand: brand ? brand.value : undefined,
    productLine: productLine ? productLine.value : undefined,
    addAfter: addAfter ? new Date(addAfter).getTime() / 1000 : undefined,
  }
  const partNumber = partialPartNumber ? partialPartNumber : undefined
  const partDesc = partialPartDesc ? partialPartDesc : undefined
  const sizeConditions = {
    comparisonSign: comparisonSign ? (comparisonSign as ComparisonEnum) : undefined,
    sizeOne: sizeOne ? +sizeOne : undefined,
    sizeTwo: sizeTwo ? +sizeTwo : undefined,
    sizeThree: sizeThree ? +sizeThree : undefined,
  }
  return {
    args,
    sizeConditions,
    partialPartNumber: partNumber,
    partialPartDesc: partDesc,
  }
}

const loadRecentSearchParams = (): Values => {
  const str = localStorage.getItem(LOCAL_STORAGE_KEY)
  return str && JSON.parse(str)
}

const AdvanceMode: React.SFC<Props> = ({ history }) => {
  const handleFormSubmit = (values: Values) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(values))
    history.push('/search', { ...values, searchMode: SearchMode.Advance })
  }
  const recentSearchParams = loadRecentSearchParams()
  return (
    <Formik
      initialValues={recentSearchParams || initialValues}
      onSubmit={handleFormSubmit}
      render={(props: FormikProps<Values>) => {
        const variables = getSearchParams(props.values)
        const clear = () => props.resetForm(initialValues)
        return (
          <Flex flex="1">
            <Flex flex="2">
              <AdvanceForm {...props} />
            </Flex>
            <Flex flex="1" flexDirection="column" paddingTop="calc(0.3215rem + 14px)" marginLeft="2rem">
              <CountResultData variables={variables}>
                {({ data, loading }) => (
                  <Button
                    primary={data && !!data.countResults}
                    block
                    large
                    onClick={props.submitForm}
                    loading={loading}>
                    {data && data.countResults ? `Show ${numberFormat(data.countResults)} results` : 'No data'}
                  </Button>
                )}
              </CountResultData>
              <Button ghost block large marginTop="0.5rem" onClick={clear}>
                Clear
              </Button>
            </Flex>
          </Flex>
        )
      }}
    />
  )
}

AdvanceMode.defaultProps = defaultProps

export default withRouter(AdvanceMode)
