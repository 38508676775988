import { SearchSuggestionData } from '@curvo/apollo'
import {
  ClickedOutside,
  Colors,
  Flex,
  lighten,
  MarginProps,
  NormalText,
  OptionContainer,
  OptionsContainer,
  TextInput,
} from '@curvo/common-ui'
import { TextInputProps } from '@curvo/common-ui/dist/components/Inputs/TextInput'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import LoadingIndicator from '../../../components/LoadingIndicator'
import DebounceInput from './Selects/DebounceInput'

const InputContainer = styled(Flex)`
  position: relative;
  width: 100%;
  align-items: center;
`
const CustomOptionsContainer = styled(OptionsContainer)`
  max-height: unset;
  padding: 16px;
`
const CustomOptionContainer = styled(OptionContainer)`
  :hover {
    background: ${lighten(Colors.Secondary, 0.5)};
  }
  &.active {
    background: ${lighten(Colors.Secondary, 0.5)};
  }
`
const SectionTitle = styled(OptionContainer)`
  border-bottom-color: ${Colors.Secondary};
  border-bottom-style: solid;
  border-bottom-width: 1px;

  color: ${Colors.Secondary};
  font-weight: 500;
  cursor: initial;
  :hover {
    background: ${Colors.White};
  }
`
const HighlightText = styled(NormalText)`
  font-weight: 500;
`
type SuggestProps = {
  onClick: () => void
  inputValue: string
  suggestValue: string
  active?: boolean
}
const SuggestOption: React.SFC<SuggestProps> = props => {
  const { suggestValue, onClick, inputValue, active } = props
  // highlight match part
  const colorSuggest = () => {
    let frontText = ''
    let matchText = ''
    let endText = ''

    const inputLength = inputValue.length
    if (inputLength > 0) {
      const searchIndex = suggestValue.toLowerCase().indexOf(inputValue.toLowerCase())
      if (searchIndex === -1) {
        endText = suggestValue
      } else {
        frontText = suggestValue.slice(0, searchIndex)
        matchText = suggestValue.slice(searchIndex, searchIndex + inputLength)
        endText = suggestValue.slice(searchIndex + inputLength)
      }
    } else {
      endText = suggestValue
    }
    return (
      <React.Fragment>
        <NormalText secondary>{frontText}</NormalText>
        <HighlightText>{matchText}</HighlightText>
        <NormalText secondary>{endText}</NormalText>
      </React.Fragment>
    )
  }

  return (
    <CustomOptionContainer className={active ? 'active' : ''} onClick={onClick}>
      {colorSuggest()}
    </CustomOptionContainer>
  )
}

type State = {
  showDropdown: boolean
  activeSectionIndex: number
  activeItemIndex: number
}
type TextInputWithSuggestProps = MarginProps & {
  value?: string | string[] | number
  submitForm?: any
  setFieldValue?: any
  fieldValue?: string
  inputProps?: TextInputProps
}

class TextInputWithSuggest extends React.Component<TextInputWithSuggestProps, State> {
  readonly state = {
    showDropdown: false,
    activeSectionIndex: -1,
    activeItemIndex: -1,
  }

  private openSuggest = () => {
    this.setState({ showDropdown: true })
  }
  private closeSuggest = () => {
    this.setState({
      activeSectionIndex: -1,
      activeItemIndex: -1,
      showDropdown: false,
    })
  }
  private trimValue = (value: string) => {
    // trim value
    return value.replace(/^\s+|\s+$/gm, '').toUpperCase()
  }
  private onChangeValue = event => {
    const { setFieldValue, fieldValue } = this.props

    if (setFieldValue && fieldValue) {
      setFieldValue(fieldValue, event.target.value.toUpperCase())
    }
    if (!this.state.showDropdown) {
      this.openSuggest()
    }
  }
  private onSelect = async (selectValue: string) => {
    const { submitForm, setFieldValue, fieldValue } = this.props
    if (submitForm && setFieldValue && fieldValue) {
      await setFieldValue(fieldValue, `"${selectValue.toUpperCase()}"`)
      submitForm()
    }

    this.closeSuggest()
  }
  private canCloneChild = (input: any): input is ReactElement<any> => {
    return input.type !== undefined
  }
  private buildChildWithProps = (deboundChangeFuntion: (value: string) => void) => {
    const { children, value, inputProps } = this.props
    const props = {
      inputProps,
      onChange: event => {
        this.onChangeValue(event)
        deboundChangeFuntion(this.trimValue(event.target.value))
      },
      value,
    }
    return React.Children.map(children, child => {
      if (this.canCloneChild(child)) {
        return React.cloneElement(child, props)
      } else {
        return child
      }
    })
  }

  render() {
    const { showDropdown } = this.state
    const { margin, marginBottom, marginLeft, marginRight, marginTop, inputProps, value, children } = this.props

    return (
      <ClickedOutside shouldHandleClick={showDropdown} onClickOutside={this.closeSuggest}>
        {({ ref }) => (
          <InputContainer
            ref={ref}
            margin={margin}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            marginTop={marginTop}
            onFocus={this.openSuggest}>
            <DebounceInput>
              {({ searchText, onInputChange }) => (
                <React.Fragment>
                  {children ? (
                    this.buildChildWithProps(onInputChange)
                  ) : (
                    <TextInput
                      {...inputProps}
                      value={value}
                      onChange={event => {
                        this.onChangeValue(event)
                        const replace = event.target.value.replace(/[^a-zA-Z0-9]/g, '')
                        onInputChange(this.trimValue(replace))
                      }}
                    />
                  )}
                  {showDropdown && searchText && this.trimValue(searchText).length > 1 && (
                    <SearchSuggestionData variables={{ searchText }}>
                      {({ data, loading }) => {
                        if (loading) {
                          return (
                            <CustomOptionsContainer>
                              <LoadingIndicator center />
                            </CustomOptionsContainer>
                          )
                        }
                        const result = data && data.searchSuggestion && data.searchSuggestion[0]
                        return result && result.items.length > 0 ? (
                          <CustomOptionsContainer>
                            {result.items.map((section, sectionIndex) => (
                              <React.Fragment key={section.type + sectionIndex}>
                                <SectionTitle>{section.type}</SectionTitle>
                                {!!section.text.length &&
                                  section.text.map((suggest, suggestIndex) => (
                                    <SuggestOption
                                      key={suggest + suggestIndex}
                                      onClick={() => {
                                        const key = suggest.split(' | ')[0].replace(/[^a-zA-Z0-9]/g, '')
                                        this.onSelect(key)
                                        onInputChange(this.trimValue(key))
                                      }}
                                      suggestValue={suggest}
                                      inputValue={result.matchText}
                                    />
                                  ))}
                              </React.Fragment>
                            ))}
                            <CustomOptionContainer
                              onClick={() => {
                                this.onSelect(searchText)
                                onInputChange(this.trimValue(searchText))
                              }}>
                              Search for "{this.trimValue(searchText)}"
                            </CustomOptionContainer>
                          </CustomOptionsContainer>
                        ) : (
                          <CustomOptionsContainer>
                            <SectionTitle paddingLeft="12px">No suggestion</SectionTitle>
                            <CustomOptionContainer
                              onClick={() => {
                                this.onSelect(searchText)
                                onInputChange(this.trimValue(searchText))
                              }}>
                              Search for "{this.trimValue(searchText)}"
                            </CustomOptionContainer>
                          </CustomOptionsContainer>
                        )
                      }}
                    </SearchSuggestionData>
                  )}
                </React.Fragment>
              )}
            </DebounceInput>
          </InputContainer>
        )}
      </ClickedOutside>
    )
  }
}

export default TextInputWithSuggest
