import { ProductLine } from '@curvo/apollo'
import { DatePickerInput, Flex, GroupInput, TextInput } from '@curvo/common-ui'
import { FormikProps } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Values } from './AdvanceMode'
import ComparionSignsSelect from './Selects/ComparisonSigns'
import GicsSelect from './Selects/Gics'
import GicTypeOnesSelect from './Selects/GicTypeOnes'
import GicTypeTwosSelect from './Selects/GicTypeTwos'
import ManufacturersSelect from './Selects/Manufacturers'
import MaterialsSelect from './Selects/Materials'
import ProductLinesSelect from './Selects/ProductLines'
//import SegmentsSelect from './Selects/Segments'
import SegmentationSelect from './Selects/Segmentation'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & FormikProps<Values>

export const transformDataToOption = (data: any[]) => {
  if (!data) {
    return []
  }
  return data.map(({ node }) => ({
    extra: node.id,
    label: node.name,
    value: node.id,
  }))
}

const ClearButton = styled(Flex)`
  cursor: pointer;
`

const AdvanceForm: React.SFC<Props> = ({ values, setFieldValue, handleChange, resetForm }) => {
  return (
    <Flex>
      <Flex flexDirection="column" flex="1" marginRight="2rem">
        <ManufacturersSelect
          value={values.manufacturer}
          onChange={manufacturer => setFieldValue('manufacturer', manufacturer)}
        />
        <GicsSelect
          value={values.gic}
          manufacturer={values.manufacturer}
          onChange={gic => {
            setFieldValue('gic', gic)
            setFieldValue('typeOne', undefined)
            setFieldValue('typeTwo', undefined)
          }}
        />
        <GroupInput>
          <ComparionSignsSelect
            value={values.comparisonSign}
            onChange={comparisonSign => setFieldValue('comparisonSign', comparisonSign.value)}
          />
          <TextInput
            type="number"
            label="1"
            disabled={values.comparisonSign ? false : true}
            value={values.sizeOne}
            name="sizeOne"
            onChange={handleChange}
          />
          <TextInput
            type="number"
            label="2"
            value={values.sizeTwo}
            disabled={values.comparisonSign ? false : true}
            name="sizeTwo"
            onChange={handleChange}
          />
          <TextInput
            type="number"
            label="3"
            disabled={values.comparisonSign ? false : true}
            value={values.sizeThree}
            name="sizeThree"
            onChange={handleChange}
          />
        </GroupInput>
        <TextInput
          label="Partial Part #"
          name="partialPartNumber"
          value={values.partialPartNumber.toUpperCase()}
          onChange={handleChange}
        />
        <SegmentationSelect
          value={values.segmentation}
          onChange={segmentation => setFieldValue('segmentation', segmentation)}
        />
      </Flex>
      <Flex flexDirection="column" flex="1">
        <DatePickerInput
          label="Added After"
          value={values.addAfter}
          onDateChange={addAfter => setFieldValue('addAfter', addAfter)}
          appendElements={
            values.addAfter ? (
              <ClearButton
                onClick={() => resetForm({ ...values, addAfter: '' })}
                justifyContent="center"
                alignItems="center">
                <svg height="20" width="20" viewBox="0 0 20 20">
                  <path
                    fill="#999"
                    d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                  />
                </svg>
              </ClearButton>
            ) : undefined
          }
        />
        <GroupInput>
          <GicTypeOnesSelect
            gicId={values.gic ? values.gic.extra : undefined}
            value={values.typeOne}
            onChange={typeOne => setFieldValue('typeOne', typeOne)}
            onClearValue={() => setFieldValue('typeTwo', undefined)}
          />
          <GicTypeTwosSelect
            gicId={values.gic ? values.gic.extra : undefined}
            gicTypeOneId={values.typeOne ? values.typeOne.id : undefined}
            value={values.typeTwo}
            onChange={typeTwo => setFieldValue('typeTwo', typeTwo)}
          />
        </GroupInput>
        <MaterialsSelect value={values.material} onChange={material => setFieldValue('material', material)} />
        <TextInput
          label="Partial Part Desc"
          name="partialPartDesc"
          value={values.partialPartDesc}
          onChange={handleChange}
        />
        <ProductLinesSelect
          value={values.productLine}
          onChange={productLine => {
            setFieldValue('productLine', productLine)
            if (productLine) {
              setFieldValue('manufacturer', {
                value: (productLine.extra as ProductLine).manufacturer.id,
                label: (productLine.extra as ProductLine).manufacturer.name,
              })
            }
          }}
        />
      </Flex>
    </Flex>
  )
}

AdvanceForm.defaultProps = defaultProps

export default AdvanceForm
