import { Colors, extractMarginAndPadding, Flex, MarginProps } from '@curvo/common-ui'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import styled from 'styled-components'

const defaultProps = {}

const initialState = {
  selected: 0,
}

type Props = Partial<typeof defaultProps> &
  MarginProps & {
    data: string[]
  }

type State = Readonly<typeof initialState>

const Container = styled(Flex)`
  width: 240px;
`

const ListContainer = styled(Flex)`
  flex-wrap: nowrap;
  overflow: auto;
  min-height: 0;
`

const ImageContainer = styled(Flex)<{
  isSelected?: boolean
  width?: number
  height?: number
  showCursor?: boolean
}>`
  width: ${props => `${props.width || '240'}px`};
  height: ${props => `${props.height || '240'}px`};
  border-radius: 3px;
  border: 1px solid rgba(195, 209, 219, 0.38);
  position: relative;
  ${props => (props.showCursor ? 'cursor: pointer;' : undefined)}
  flex: 0 0 auto;
  overflow: hidden;
  &:after {
    display: ${props => (props.isSelected ? 'block' : 'none')};
    content: '';
    background: ${Colors.Primary};
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Image = styled('img')`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

export default class ImageGallery extends React.Component<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  private handleSelectImage = (selected: number) => this.setState({ selected })

  render() {
    const { selected } = this.state
    const { data, ...rest } = this.props
    const { margins } = extractMarginAndPadding(rest)
    return (
      <Container flexDirection="column" {...margins}>
        <ImageContainer marginBottom="1rem">
          <Image src={data[selected]} />
        </ImageContainer>
        <PerfectScrollbar>
          <ListContainer>
            {data.map((item, imageIndex) => (
              <ImageContainer
                key={item}
                onClick={() => this.handleSelectImage(imageIndex)}
                isSelected={imageIndex === selected}
                width={50}
                height={50}
                marginRight={imageIndex + 1 === data.length ? '0px' : '13px'}
                showCursor>
                <Image src={item} />
              </ImageContainer>
            ))}
          </ListContainer>
        </PerfectScrollbar>
      </Container>
    )
  }
}
