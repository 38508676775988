import { NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import { ToggleIcon } from '../../../components/Icons'
import { SearchMode } from '../Homepage'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  value: SearchMode
  onChange: FuncVoid
}

const getLabel = (id: SearchMode) => {
  switch (id) {
    case SearchMode.Advance:
      return 'Advanced'
    default:
      return 'Basic'
  }
}

const CurrentMode = styled(NormalText)`
  user-select: none;
  cursor: pointer;
`

const ChangeModeButton: React.SFC<Props> = ({ value, onChange }) => (
  <CurrentMode size="2rem" light onClick={onChange} marginRight="1rem">
    {getLabel(value)}
    <ToggleIcon />
  </CurrentMode>
)

ChangeModeButton.defaultProps = defaultProps

export default ChangeModeButton
