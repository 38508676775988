import { editCreditCard, removeCreditCard, setDefaultCreditCard, StripeCardType } from '@curvo/apollo'
import { Button, CheckboxInput, Flex, NormalText, Notify } from '@curvo/common-ui'
import React from 'react'
import CardBrand, { Size } from '../../../components/CardBrand'
import { CardNumberPlaceholder } from '../../../components/Icons'
import { Container } from './ChangePasswordForm'

const defaultProps = {}

const initialState = {
  setDefaultLoading: false,
  removeCardLoading: false,
  shouldCopyAddress: false,
}

type Props = Partial<typeof defaultProps> & {
  card: StripeCardType
  defaultCard: StripeCardType
  isDefault: boolean
  onRequestToClose: FuncVoid
}

type State = Readonly<typeof initialState>

export default class EditCardForm extends React.Component<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  constructor(props: Props) {
    super(props)
    this.state = {
      ...initialState,
      shouldCopyAddress: this.shouldCopyAddress(props.card),
    }
  }

  private shouldCopyAddress = (card: StripeCardType): boolean =>
    !(!!card.addressCity && !!card.addressLine1 && !!card.addressState && !!card.name)

  private mounted: boolean = false

  private handleSetDefault = async () => {
    const { card, onRequestToClose, defaultCard } = this.props
    const { shouldCopyAddress } = this.state
    try {
      this.setState({ setDefaultLoading: true })
      if (shouldCopyAddress) {
        const { addressCity, addressCountry, addressLine1, addressLine2, addressState, name } = defaultCard
        await Promise.all([
          setDefaultCreditCard({ cardId: card.id }),
          editCreditCard({
            cardId: card.id,
            input: {
              name,
              addressCity,
              addressCountry,
              addressLine1,
              addressLine2,
              addressState,
            },
          }),
        ])
      } else {
        await setDefaultCreditCard({ cardId: card.id })
      }
      if (this.mounted) {
        this.setState({ setDefaultLoading: false })
      }
      Notify.primary('Default card was changed!')
      onRequestToClose()
    } catch (error) {
      Notify.danger(error.message || "Can't change default card! Please try again later!")
      onRequestToClose()
    }
  }

  private handleRemoveCard = async () => {
    const { card, onRequestToClose } = this.props
    try {
      this.setState({ removeCardLoading: true })
      await removeCreditCard({ cardId: card.id })
      Notify.primary('Card was removed!')
      if (this.mounted) {
        this.setState({ removeCardLoading: false })
        onRequestToClose()
      }
    } catch (error) {
      Notify.danger(error.message || "Can't change default card! Please try again later!")
      onRequestToClose()
    }
  }

  private handleCopyAddress = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ shouldCopyAddress: event.target.checked })

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    const { setDefaultLoading, removeCardLoading, shouldCopyAddress } = this.state
    const { card, isDefault, onRequestToClose } = this.props
    return (
      <Container>
        <NormalText size="1.25rem" marginBottom="0.5rem">
          Edit Card
        </NormalText>
        <NormalText secondary marginBottom="1rem">
          {isDefault && 'Default Method'}&nbsp;
        </NormalText>
        <Flex alignItems="center">
          <CardBrand type={card.brand ? card.brand : ''} size={Size.Small} />
          <NormalText marginLeft="0.5rem" size="1.2rem">
            <CardNumberPlaceholder />
            &nbsp;&nbsp;&nbsp;{card.last4}
          </NormalText>
        </Flex>
        {!isDefault && (
          <CheckboxInput
            marginTop="1rem"
            label="Same billing address with current card"
            checked={shouldCopyAddress}
            onChange={this.handleCopyAddress}
          />
        )}
        <Flex justifyContent="flex-end" marginBottom="1rem" marginTop="1.5rem">
          {!isDefault && (
            <React.Fragment>
              <Button
                marginRight="0.5rem"
                onClick={this.handleSetDefault}
                loading={setDefaultLoading}
                disabled={removeCardLoading}>
                Set as Default
              </Button>
              <Button
                marginRight="0.5rem"
                danger
                onClick={this.handleRemoveCard}
                loading={removeCardLoading}
                disabled={setDefaultLoading}>
                Remove Card
              </Button>
            </React.Fragment>
          )}
          <Button secondary onClick={onRequestToClose}>
            Cancel
          </Button>
        </Flex>
      </Container>
    )
  }
}
