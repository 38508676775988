import { Button, NormalText, Notify } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import { History } from 'history'
import React from 'react'
import Helmet from 'react-helmet'
import { Link, RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'
import Cognito from '../../configs/Cognito'
import useAuthorizedRoute from './hooks/useAuthorizedRoute'
import ForgetPasswordForm from './ResetPasswordForm'
import { Container } from './SignIn'

const defaultProps = {}

const initialValues = {
  email: '',
  sentCode: false,
  code: '',
  password: '',
  confirmPassword: '',
}

type Props = Partial<typeof defaultProps> & RouteComponentProps & {}

export type Values = typeof initialValues & {
  formError?: string
}

const validationSchema = () => {
  const shape = {
    email: Yup.string().email('Email is not valid!').required('Email is required!'),
    sentCode: Yup.bool(),
    code: Yup.mixed().when('sentCode', {
      is: true,
      then: Yup.string().required('Code is required!'),
    }),
    password: Yup.mixed().when('sentCode', {
      is: true,
      then: Yup.string()
        .min(8, 'Minimum length of 8')
        .max(256, ' Maximum length of 256')
        .matches(
          new RegExp(
            // prettier-ignore
            // eslint-disable-next-line no-useless-escape
            '^(?=(?:.*[A-Z]){1,})(?=(?:.*[0-9]){1,})(?=(?:.*[!"#$%&\'()*+,.\/:;<=>?@^_`{|}~-]))(.{8,256})$',
          ),
          'Password is too weak! Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
        )
        // .matches(
        //   /^(?=(?:.*[A-Z]){1,})(?=(?:.*[0-9]){1,})(.{8,256})$/,
        //   'Input password is too weak. Strong password needs to contain Uppercase & Numeric characters.',
        // )
        .required('Password is required'),
    }),
    confirmPassword: Yup.mixed().when('sentCode', {
      is: true,
      then: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Password confirmation does not match')
        .required('Password confirmation is required'),
    }),
  }
  return Yup.object().shape(shape)
}

const onSubmit =
  (history: History) =>
  async (values: Values, { setSubmitting, setFieldValue, setFieldError }: FormikActions<Values>) => {
    try {
      if (!values.sentCode) {
        await Cognito.forgotPassword(values.email)
        setFieldValue('sentCode', true)
        setSubmitting(false)
        return
      }
      await Cognito.confirmPassword(values.email, values.code, values.password)
      Notify.primary('Password was changed!')
      setSubmitting(false)
      history.push('/auth')
    } catch (error) {
      // unable to reproduce bug for ON-639. Adding console to get more data from
      // bug appearing again on prod
      console.info('Forgot Password error: ', { error })
      setSubmitting(false)
      if (error.code === 'UserNotFoundException') {
        setFieldError('email', 'Email was not found. It either does not exist or was not confirmed.')
        return
      }
      setFieldValue('formError', error.message || 'Something went wrong!')
    }
  }

const ForgetPassword: React.SFC<Props> = ({ history }) => {
  const loading = useAuthorizedRoute(history)

  const handleSubmit = onSubmit(history)
  return loading ? null : (
    <React.Fragment>
      <Helmet>
        <title>Forgot Password | ONN (by Curvo)</title>
      </Helmet>
      <Container padding="3rem" marginTop="4rem">
        <NormalText size="2rem" center marginBottom="2rem">
          Forgot Password
        </NormalText>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={props => <ForgetPasswordForm {...props} />}
        />
        <NormalText size="0.75rem">
          Already have account?{' '}
          <Button small link>
            <Link to="/auth/signin">Sign In</Link>
          </Button>{' '}
          <Button small link marginLeft="auto">
            <Link to="/auth/signup">Create new account</Link>
          </Button>
        </NormalText>
      </Container>
    </React.Fragment>
  )
}

ForgetPassword.defaultProps = defaultProps

export default ForgetPassword
