import { TempDownloadUrlData } from '@curvo/apollo'
import { Button, Container, Flex, NormalText, Spinner as LoadingIndicator } from '@curvo/common-ui'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import Layout from '../components/Layout'

const defaultProps = {}

const initialState = {}

type Props = typeof defaultProps & RouteComponentProps<{ id: string }> & {}

type State = Readonly<typeof initialState>

const downloadFile = (url: string) => {
  setTimeout(() => (window.location.href = url), 1000)
}

const DownloadText = styled(NormalText)`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`

export default class DownloadNewsletter extends React.Component<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  render() {
    const { match } = this.props
    return (
      <Layout header={<Header title="Newsletter" />}>
        <Container justifyContent="center" alignItems="center" flexDirection="column">
          <TempDownloadUrlData
            variables={{ id: match.params.id }}
            selfHandleError
            onError={error => {
              console.error(error)
            }}>
            {({ data, loading, error }) => {
              if (loading) {
                return (
                  <Flex flexDirection="column" justifyContent="center" alignItems="center">
                    <NormalText size="2em">Your download will be ready in a moment</NormalText>
                    <Flex marginTop="16px" />
                    <LoadingIndicator center />
                  </Flex>
                )
              }
              if (data && !error) {
                // window.location.href = data.getTempDownloadUrl
                downloadFile(data.getTempDownloadUrl)
                return (
                  <Flex flexDirection="column" justifyContent="center" alignItems="center">
                    <NormalText size="2em">Your download is ready!</NormalText>
                    <DownloadText size="1em" onClick={() => downloadFile(data.getTempDownloadUrl)}>
                      Click here if your download doesn't start automatically
                    </DownloadText>
                  </Flex>
                )
              }
              // TODO: Need design for error page
              return (
                <React.Fragment>
                  <NormalText danger size="2em">
                    You don't have permission to download this file!
                  </NormalText>
                  <NormalText danger size="2em">
                    You must subscribe to Newsletters to get a download URL
                  </NormalText>
                  <Flex marginTop="16px" />
                  <Link to="/setup/subscription">
                    <Button primary>Go to Subcription</Button>
                  </Link>
                </React.Fragment>
              )
            }}
          </TempDownloadUrlData>
        </Container>
      </Layout>
    )
  }
}
