import { MeProvider } from '@curvo/apollo'
import { ResetCSS } from '@curvo/common-ui'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import { ToastContainer } from 'react-toastify'
import './assets/styles/ReactDayPicker.css'
import './assets/styles/ReactToastify.css'
import AuthorizedRoute from './components/AuthorizedRoute'
import ErrorBoundary from './components/ErrorBoundary'
import GlobalStyles from './components/GlobalStyles'
import TopErrorBoundary from './components/TopErrorBoundary'
import { WithRouterTracking } from './components/Tracking'
import Client from './configs/Apollo'
import env from './configs/env'
import Account from './routes/Account/Account'
import Setup from './routes/Account/Setup'
import Auth from './routes/Auth/Auth'
import AuthLayout from './routes/Auth/components/AuthLayout'
import DownloadNewsletter from './routes/DownloadNewsletter'
import Home from './routes/Home/Home'

const Main: React.FC = () => {
  return (
    <TopErrorBoundary>
      <ApolloProvider client={Client.getClient()}>
        <ErrorBoundary>
          <Switch>
            <Route path="/auth" component={AuthLayout(Auth)} />
            <MeProvider>
              <WithRouterTracking />
              <Route path="/setup" component={Setup} />
              <AuthorizedRoute path="/account" component={Account} />
              <AuthorizedRoute path="/download/newsletter/:id" component={DownloadNewsletter} />
              <Route path="/" component={Home} />
            </MeProvider>
          </Switch>
          <ToastContainer hideProgressBar newestOnTop draggable={false} autoClose={5000} />
        </ErrorBoundary>
      </ApolloProvider>
    </TopErrorBoundary>
  )
}

const MainWithRouter = withRouter(Main)

const App = () => {
  return (
    <React.Fragment>
      <ResetCSS />
      <GlobalStyles />
      <StripeProvider apiKey={env.STRIPE_API_PK_KEY}>
        <Router>
          <MainWithRouter />
        </Router>
      </StripeProvider>
    </React.Fragment>
  )
}

export default App
