import { ChildUsersData, inviteChildUser } from '@curvo/apollo'
import { Button, Modal, NormalText, Notify, Toggle } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import LoadingIndicator from '../../components/LoadingIndicator'
import PageLayout from './components/PageLayout'
import AccountItem from './components/SubAccount/AccountItem'
import UserForm from './components/SubAccount/UserForm'

type Props = {}

const initialValues = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  company: '',
}

const validationSchema = () => {
  const shape = {
    username: Yup.string()
      .min(6, 'Username requires at least 6 characters.')
      .lowercase('Username should be lowercase only.')
      .required('Username is required!'),
    email: Yup.string()
      .email('Email is not valid!')
      .lowercase('Email should be lowercase only.')
      .required('Email is required!'),
  }
  return Yup.object().shape(shape)
}

type Values = typeof initialValues & {
  formError?: string
}

const StyledGroup = styled('div')`
  flex: 1;
  flex-direction: column;
`

const StyledUserList = styled.div`
  flex: 1;
`

const NoData = styled('div')`
  margin-left: 1.5rem;
  font-size: 1rem;
`

const StyledNormalText = styled(NormalText)`
  font-family: Helvetica;
  font-size: 18px;
`

const handleCreateAccount =
  (onRequestToClose: () => void) =>
  async ({ formError: _formError, ...data }: Values, { setSubmitting }: FormikActions<Values>) => {
    setSubmitting(true)
    try {
      await inviteChildUser({ ...data })
      setSubmitting(false)
      Notify.success('Create new sub-account successfully!')
      onRequestToClose()
    } catch (error) {
      console.error(error)
      Notify.danger(error.message || 'Something went wrong!')
      setSubmitting(false)
    }
  }

const SubAccount: React.SFC<Props> = () => (
  <PageLayout>
    <ChildUsersData>
      {({ data, loading, error }) => {
        if (loading) {
          return <LoadingIndicator />
        }
        if (!data || error) {
          return <></>
        }
        return (
          <React.Fragment>
            <Toggle>
              {({ isActive, on, off }) => (
                <React.Fragment>
                  <Button primary alignSelf="flex-end" large onClick={on}>
                    Create New Account
                  </Button>
                  <Modal isOpen={isActive} onRequestToClose={off}>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={({ username, email, ...values }, actions) => {
                        handleCreateAccount(off)(
                          {
                            ...values,
                            username: username.toLowerCase(),
                            email: email.toLowerCase(),
                          },
                          { ...actions },
                        )
                      }}
                      validationSchema={validationSchema}>
                      {props => <UserForm {...props} />}
                    </Formik>
                  </Modal>
                </React.Fragment>
              )}
            </Toggle>
            <StyledGroup>
              {!data.childUsers.length && <NoData />}
              {data.childUsers.length > 0 && (
                <>
                  <StyledNormalText size="1.2rem" marginBottom="1rem">
                    Accounts
                  </StyledNormalText>
                  <StyledUserList>
                    {data.childUsers.map(item => (
                      <AccountItem key={item.id} user={item} />
                    ))}
                  </StyledUserList>
                </>
              )}
            </StyledGroup>
          </React.Fragment>
        )
      }}
    </ChildUsersData>
  </PageLayout>
)

export default SubAccount
