import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { WithRouterTracking } from '../../components/Tracking'
import SignUp from './SignUp'
import VerifyEmail from './VerifyCode'
import SignIn from './SignIn'
import ResetPassword from './ResetPassword'

const defaultProps = {}

type Props = typeof defaultProps & {}

const Auth: React.SFC<Props> = () => (
  <>
    <WithRouterTracking />
    <Switch>
      <Route path="/auth/signin" component={SignIn} />
      <Route path="/auth/signup" component={SignUp} />
      <Route path="/auth/verify-account" component={VerifyEmail} />
      <Route path="/auth/forget-password" component={ResetPassword} />
      <Redirect to="/auth/signin" />
    </Switch>
  </>
)

Auth.defaultProps = defaultProps

export default Auth
