import { MeContext } from '@curvo/apollo'
import { ClickedOutside, Flex, Gravatar, Toggle } from '@curvo/common-ui'
import React, { useContext } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { signOut } from '../configs/Cognito'
import LoadingIndicator from './LoadingIndicator'

const defaultProps = {
  showName: true,
}

type Props = Partial<typeof defaultProps> & RouteComponentProps & {}

const Container = styled(Flex)`
  align-items: center;
  position: relative;
`

const Username = styled('strong')`
  user-select: none;
  cursor: pointer;
`

const MenuContainer = styled(Flex)`
  position: absolute;
  background: white;
  top: 100%;
  right: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(137, 157, 166, 0.15);
  border-radius: 3px;
  min-width: 100px;
  flex-direction: column;
`

const MenuItem = styled(Flex)`
  border-bottom: 1px solid rgba(137, 157, 166, 0.15);
  padding: 0.5rem 1rem;
  cursor: pointer;
  justify-content: flex-end;
  &:last-of-type {
    border-bottom: 0;
  }
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }
`

const UserMenu: React.SFC<Props> = ({ history, showName }) => {
  const { data, loading } = useContext(MeContext)
  const handleSignOut = () => {
    signOut()
    history.push('/auth')
  }
  return loading || !data ? (
    <LoadingIndicator />
  ) : (
    <Container>
      <Toggle>
        {({ isActive, toggle, off }) => (
          <ClickedOutside onClickOutside={off} shouldHandleClick={isActive}>
            {({ ref }) => (
              <React.Fragment>
                <Gravatar
                  email={data.me.email}
                  onClick={toggle}
                  size="3rem"
                  marginRight={showName ? '1rem' : undefined}
                />
                {showName && (
                  <Username onClick={toggle}>{`${data.me.firstName} ${data.me.lastName} ${
                    !data.me.firstName && !data.me.lastName ? 'No Name' : ''
                  }`}</Username>
                )}
                {isActive && (
                  <MenuContainer ref={ref}>
                    <MenuItem>
                      <Link to="/account/profile">Profile</Link>
                    </MenuItem>
                    <MenuItem onClick={handleSignOut}>Log out</MenuItem>
                  </MenuContainer>
                )}
              </React.Fragment>
            )}
          </ClickedOutside>
        )}
      </Toggle>
    </Container>
  )
}

UserMenu.defaultProps = defaultProps

export default withRouter(UserMenu)
