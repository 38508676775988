import { signUp } from '@curvo/apollo'
import { Button, NormalText } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import { History } from 'history'
import React from 'react'
import Helmet from 'react-helmet'
import { Link, RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'
import useAuthorizedRoute from './hooks/useAuthorizedRoute'
import { Container } from './SignIn'
import SignUpForm from './SignUpForm'

const defaultProps = {}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  username: '',
  agreeTerms: false,
}

type Props = typeof defaultProps & RouteComponentProps & {}

export type Values = typeof initialValues & {
  formError?: string
}

const onSignUp =
  (history: History) =>
  async (
    { confirmPassword: _confirmPassword, ...values }: Values,
    { setSubmitting, setFieldValue }: FormikActions<Values>,
  ) => {
    try {
      await signUp({ ...values, username: values.username.toLowerCase() })
      setSubmitting(false)
      history.push('/auth/verify-account', {
        email: values.email,
        username: values.username.toLowerCase(),
      })
    } catch (error) {
      setFieldValue('formError', error.message || 'Something went wrong!')
      setSubmitting(false)
    }
  }

const validateSchema = () => {
  const shape = {
    email: Yup.string()
      .email('Email is not valid')
      .lowercase('Email should be lowercase only.')
      .required('Email is required'),
    password: Yup.string()
      .min(8, 'Minimum length of 8')
      .max(256, ' Maximum length of 256')
      .matches(
        new RegExp(
          // prettier-ignore
          // eslint-disable-next-line no-useless-escape
          '^(?=(?:.*[A-Z]){1,})(?=(?:.*[0-9]){1,})(?=(?:.*[!"#$%&\'()*+,.\/:;<=>?@^_`{|}~-]))(.{8,256})$',
        ),
        'Password too weak! Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Confirm Password is not match')
      .required('Confirm Password is required'),
    username: Yup.string().lowercase('Username should be lowercase only.').required('Username is required!'),
  }
  return Yup.object().shape(shape)
}

const SignUp: React.SFC<Props> = ({ history }) => {
  const loading = useAuthorizedRoute(history)

  const handleSubmit = onSignUp(history)
  return loading ? null : (
    <React.Fragment>
      <Helmet>
        <title>Sign Up | ONN (by Curvo)</title>
      </Helmet>
      <Container padding="3rem" marginTop="4rem">
        <NormalText size="2rem" center marginBottom="3rem">
          Sign Up
        </NormalText>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validateSchema}
          render={props => <SignUpForm {...props} />}
        />
      </Container>
      <NormalText size="0.75rem" marginTop="2rem">
        Already have an account?
        <Button link small>
          <Link to="/auth/signin">Log In</Link>
        </Button>
      </NormalText>
    </React.Fragment>
  )
}

SignUp.defaultProps = defaultProps

export default SignUp
