import React from 'react'
import { NormalText, Button, Flex, Notify, Alert } from '@curvo/common-ui'
import { CardElement, injectStripe, Elements, ReactStripeElements } from 'react-stripe-elements'
import { addCreditCard } from '@curvo/apollo'
import CardInput from '../../../components/CardInput'
import { Lock } from '../../../components/Icons'
import { Container } from './ChangePasswordForm'

const defaultProps = {}

const initialState = {
  loading: false,
  formError: '',
}

type Props = typeof defaultProps &
  ReactStripeElements.InjectedStripeProps & {
    onRequestToClose: FuncVoid
  }

type State = Partial<Readonly<typeof initialState>>

class AddNewCardInput extends React.Component<Props, State> {
  private mounted: boolean = false

  static defaultProps = defaultProps

  readonly state: State = initialState

  private handleAddNewCard = async () => {
    this.setState({
      loading: true,
    })
    if (this.props.stripe) {
      try {
        const { token } = await this.props.stripe.createToken()
        if (token) {
          await addCreditCard({ tokenId: token.id })
          Notify.primary('New card added!')
          this.props.onRequestToClose()
        }
      } catch (error) {
        this.setState({ formError: error.message || 'Something went wrong!' })
      }
      if (this.mounted) {
        this.setState({ loading: false })
      }
    }
  }

  public componentDidMount() {
    this.mounted = true
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  render() {
    return (
      <Container>
        <NormalText size="1.25rem" marginBottom="2rem">
          Add New Card
        </NormalText>
        {this.state.formError && (
          <Alert danger marginBottom="1rem">
            {this.state.formError}
          </Alert>
        )}
        <CardInput large>
          <CardElement />
        </CardInput>
        <Flex justifyContent="flex-end">
          <Button
            large
            marginBottom="1rem"
            marginTop="1rem"
            loading={this.state.loading}
            onClick={this.handleAddNewCard}>
            <Lock /> Save Card
          </Button>
        </Flex>
        <NormalText center secondary>
          You may see a temporary authorization hold on your card, which your bank should release soon.
        </NormalText>
      </Container>
    )
  }
}

const InjectedNewCardInput = injectStripe(AddNewCardInput)

const AddNewCardForm = (props: Props) => (
  <Elements>
    <InjectedNewCardInput {...props} />
  </Elements>
)

export default AddNewCardForm
