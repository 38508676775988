import { NormalText, Flex, LinkText } from '@curvo/common-ui'
import React from 'react'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {}

const Footer: React.SFC<Props> = () => (
  <Flex flexDirection="row">
    <LinkText
      href="https://vault.pactsafe.io/s/721e69ab-720f-4525-af86-304abdd0a676/legal.html"
      secondary
      margin="1rem 1rem">
      Legal
    </LinkText>
    <NormalText secondary margin="1rem 1rem">
      &copy; © 2020 Curvo Labs, Inc - © 2020 Mendenhall Associates, Inc
    </NormalText>
  </Flex>
)

Footer.defaultProps = defaultProps

export default Footer
