import { User } from '@curvo/apollo'
import { Alert, Button, Colors, Flex, GroupInput, NormalText, TextInput } from '@curvo/common-ui'
import { FormikProps, FormikValues } from 'formik'
import React from 'react'
import styled from 'styled-components'

type Props = {
  isEditting?: boolean
  data?: User
} & FormikProps<FormikValues>

const Container = styled(Flex)`
  flex-direction: column;
  padding: 2rem;
  background: ${Colors.White};
  border: 1px solid ${Colors.Border};
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  min-width: 600px;
`

const UserForm: React.SFC<Props> = ({
  isEditting = false,
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  isSubmitting,
}) => (
  <Container>
    <form onSubmit={handleSubmit}>
      <NormalText size="1.25rem" marginBottom="2rem">
        {isEditting ? 'Update Info' : 'Create Sub-Account'}
      </NormalText>
      {values.formError && (
        <Alert danger marginBottom="1rem">
          {values.formError}
        </Alert>
      )}
      <TextInput
        name="username"
        value={values.username.toLowerCase()}
        placeholder="Username"
        onChange={handleChange}
        error={touched.username ? errors.username && errors.username.toString() : undefined}
        disabled={isEditting}
      />
      <GroupInput>
        <TextInput
          name="firstName"
          value={values.firstName}
          placeholder="First Name"
          onChange={handleChange}
          error={touched.firstName ? errors.firstName && errors.firstName.toString() : undefined}
        />
        <TextInput
          name="lastName"
          value={values.lastName}
          placeholder="Last Name"
          onChange={handleChange}
          error={touched.lastName ? errors.lastName && errors.lastName.toString() : undefined}
        />
      </GroupInput>
      <TextInput
        name="email"
        value={values.email.toLowerCase()}
        placeholder="Email"
        onChange={handleChange}
        error={touched.email ? errors.email && errors.email.toString() : undefined}
        disabled={isEditting}
      />
      <TextInput
        name="phoneNumber"
        value={values.phoneNumber}
        placeholder="Phone number"
        onChange={handleChange}
        error={touched.phoneNumber ? errors.phoneNumber && errors.phoneNumber.toString() : undefined}
      />
      <TextInput
        name="company"
        value={values.company}
        placeholder="Company"
        onChange={handleChange}
        error={touched.company ? errors.company && errors.company.toString() : undefined}
      />
      <Flex justifyContent="flex-end">
        <Button primary large type="submit" loading={isSubmitting}>
          {isEditting ? 'Update' : 'Create'}
        </Button>
      </Flex>
    </form>
  </Container>
)

export default UserForm
