import { AlertButton, Card, Flex, NormalText, Notify } from '@curvo/common-ui'
import React from 'react'
import { deactivateAccount } from '@curvo/apollo'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  onSignOut: () => void
}

const DeactivateAccount: React.SFC<Props> = ({ onSignOut }) => {
  const handleDeactivateAccount = async () => {
    try {
      await deactivateAccount({})
      onSignOut()
    } catch (error) {
      Notify.danger(error.message || 'Something went wrong!')
    }
  }
  return (
    <Card padding="2rem" flexDirection="row" marginBottom="2rem">
      <Flex flexDirection="column" justifyContent="center" flex={1}>
        <NormalText size="1.125rem">Deactivate account</NormalText>
      </Flex>
      <AlertButton danger large onConfirm={handleDeactivateAccount}>
        Deactivate account
      </AlertButton>
    </Card>
  )
}

DeactivateAccount.defaultProps = defaultProps

export default DeactivateAccount
