import qs from 'querystring'
import { Button, Container, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router'
import background from '../../assets/images/homepage-background.svg'
import Header from '../../components/Header'
import Layout from '../../components/Layout'
import AdvanceMode from './components/AdvanceMode'
import BasicMode from './components/BasicMode'
import Footer from './components/Footer'
import { LegacyUserWarning } from './components/LegacyUserWarning'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & RouteComponentProps

const SearchContainer = styled(Flex)`
  min-width: 1000px;
  flex-direction: column;
`
export enum SearchMode {
  Basic = 'BASIC',
  Advance = 'ADVANCED',
}

const initialState = {
  searchMode: SearchMode.Basic,
}

type State = Readonly<
  typeof initialState & {
    searchMode: SearchMode
  }
>

const StyledContainer = styled(Container)`
  min-height: 1px;
`

export default class Homepage extends React.Component<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  private handleChangeToBasicMode = () => this.setState({ searchMode: SearchMode.Basic })
  private handleChangeToAdvancedMode = () => this.setState({ searchMode: SearchMode.Advance })

  componentWillMount() {
    const { location } = this.props
    const mode = qs.parse(location.search.substring(1)).mode as string
    this.setState({
      searchMode: mode && mode === 'advance' ? SearchMode.Advance : initialState.searchMode,
    })
  }

  render() {
    const { handleChangeToBasicMode, handleChangeToAdvancedMode } = this
    const { searchMode } = this.state

    return (
      <Layout
        background={`url(${background}) no-repeat top right`}
        header={<Header transparent showName={false} />}
        showFooter
        footer={Footer}>
        <Helmet>
          <title>ONN (by Curvo)</title>
        </Helmet>
        <StyledContainer flex="1" flexDirection="column" alignItems="center" style={{}}>
          <LegacyUserWarning />
          <SearchContainer>
            <NormalText size="4.5rem" marginTop="4rem" extraBold>
              Find
            </NormalText>
            <NormalText size="4.5rem">A-Part</NormalText>
            <Flex marginBottom="2rem">
              <Button
                primary={searchMode === SearchMode.Basic}
                secondary={searchMode !== SearchMode.Basic}
                marginRight="0.5rem"
                onClick={handleChangeToBasicMode}>
                Basic
              </Button>
              <Button
                primary={searchMode === SearchMode.Advance}
                secondary={searchMode !== SearchMode.Advance}
                onClick={handleChangeToAdvancedMode}>
                Advanced
              </Button>
            </Flex>
            {searchMode === SearchMode.Basic && <BasicMode />}
            {searchMode === SearchMode.Advance && <AdvanceMode />}
          </SearchContainer>
        </StyledContainer>
      </Layout>
    )
  }
}
