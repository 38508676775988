import { User } from '@curvo/apollo'
import { Button, Card, Flex, Modal, NormalText, Toggle } from '@curvo/common-ui'
import React from 'react'
import ChangePasswordForm from './ChangePasswordForm'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  data: User
}

const ChangePassword: React.SFC<Props> = ({ data }) => (
  <Card padding="2rem" flexDirection="row" marginBottom="2rem">
    <Flex flexDirection="column" justifyContent="center" flex={1}>
      <NormalText size="1.125rem">Password</NormalText>
    </Flex>
    <Toggle>
      {({ isActive, on, off }) => (
        <React.Fragment>
          <Button primary large alignSelf="center" onClick={on}>
            Change Password
          </Button>
          <Modal isOpen={isActive} onRequestToClose={off}>
            <ChangePasswordForm onRequestToClose={off} data={data} />
          </Modal>
        </React.Fragment>
      )}
    </Toggle>
  </Card>
)

ChangePassword.defaultProps = defaultProps

export default ChangePassword
