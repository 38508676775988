import { Flex, renderElement } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import Header from './Header'

const defaultProps = {
  showHeader: true,
  showFooter: false,
}

type Props = Partial<typeof defaultProps> & {
  header?: React.ReactNode
  footer?: React.ReactNode
  title?: string
  background?: string
}

const Container = styled(Flex)<{ background?: string }>`
  flex-direction: column;
  ${props => props.background && `background: ${props.background}`};
  min-height: 100vh;
  min-width: 100%;
`

const Layout: React.SFC<Props> = ({ showHeader, header, showFooter, footer, title, children, background }) => {
  const renderHeader = renderElement(header, <Header>{title}</Header>)
  const renderFooter = renderElement(footer)
  return (
    <Flex>
      <Container background={background}>
        {showHeader && renderHeader}
        {children}
        {showFooter && renderFooter}
      </Container>
    </Flex>
  )
}

Layout.defaultProps = defaultProps

export default Layout
