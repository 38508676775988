import { Button } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import { Container, Content, DescriptionContainer, Header, PriceTag, StyledBtnCont } from './Plan'

const defaultProps = {
  recommneded: false,
}

export type PlanProps = {}

type Props = Partial<typeof defaultProps> & PlanProps & { spacing?: string }

const initialState = {
  loading: false,
}

type State = Readonly<typeof initialState>

const StyledButton = styled(Button)`
  boxshadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  color: black;
`

export default class PlanStatic extends React.PureComponent<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  render() {
    return (
      <Container>
        <Header>
          <span>Enterprise Pricing</span>
        </Header>
        <Content>
          <PriceTag>
            Newsletter <br /> Find-A-Part
          </PriceTag>
          <DescriptionContainer>Please contact us for enterprise pricing</DescriptionContainer>
          <StyledBtnCont>
            <a href="mailto:sales@curvolabs.com" target="_newtab">
              <StyledButton light block large>
                Contact Us
              </StyledButton>
            </a>
          </StyledBtnCont>
        </Content>
      </Container>
    )
  }
}
