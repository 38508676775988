import bcryptjs from 'bcryptjs'
import {
  activateFirstTimeUser,
  getDBUsernameQuery,
  updateStripeMeta,
  legacySignIn,
  getUsernameByEmail,
} from '@curvo/apollo'
import { NEW_PASSWORD_REQUIRED } from '@curvo/cognito'
import { Button, Card, NormalText } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import React from 'react'
import Helmet from 'react-helmet'
import { Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import * as H from 'history'
import Cognito, { signOut } from '../../configs/Cognito'
import useAuthorizedRoute from './hooks/useAuthorizedRoute'
import SignInForm from './SignInForm'

const defaultProps = {}

const initialValues = {
  email: '',
  password: '',
  username: '',
  newPasswordRequired: false,
  newPassword: '',
  confirmNewPassword: '',
}

type Props = typeof defaultProps &
  RouteComponentProps<any, any, { message?: string; from?: string; username?: string }> & {}

export type Values = typeof initialValues & {
  formError?: string
}

export const Container = styled(Card)`
  width: 500px;
`

const isEmail =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

const signInLegacyUser = async (username: string, password: string) => {
  // if user tries to log in with email => skip
  const hash = bcryptjs.hashSync(password)
  // try legacy sign in
  try {
    const token = await legacySignIn({ username, password: hash })
    if (token && token.data && token.data.legacySignIn) {
      localStorage.setItem('accessToken', token.data.legacySignIn)
    }
  } catch (e) {
    throw e
  }
}

const validateSchema = () => {
  const shape = {
    password: Yup.string().required('Password is required!'),
    username: Yup.string()
      .lowercase('Email or Username should be lowercase only.')
      .required('Email or Username is required!'),
    newPasswordRequired: Yup.boolean(),
    newPassword: Yup.mixed().when('newPasswordRequired', {
      is: true,
      then: Yup.string()
        .min(6, 'Minimum length of 6')
        .max(256, 'Maximum length of 256')
        .matches(
          new RegExp(
            // prettier-ignore
            // eslint-disable-next-line no-useless-escape
            '^(?=(?:.*[A-Z]){1,})(?=(?:.*[0-9]){1,})(?=(?:.*[!"#$%&\'()*+,.\/:;<=>?@^_`{|}~-]))(.{8,256})$',
          ),
          'Password too weak! Password must contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
        )
        .required('New Password is required!'),
    }),
    confirmNewPassword: Yup.mixed().when('newPasswordRequired', {
      is: true,
      then: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Confirm Password is not match')
        .min(6, 'Minimum length of 6')
        .max(256, ' Maximum length of 256')
        .required('Confirm Password is required'),
    }),
  }
  return Yup.object().shape(shape)
}

function afterLoggedIn(history: H.History, destination: string = '/') {
  history.push(destination, { justLoggedIn: true })
}

const SignIn: React.SFC<Props> = ({ history, location }) => {
  const loading = useAuthorizedRoute(history)
  const handleSignIn = async (values: Values, formikActions: FormikActions<Values>) => {
    const { setSubmitting, setFieldValue } = formikActions
    try {
      // fetch username from db, then send it to Cognito
      const { usernameFromDB } = await getDBUsernameQuery(values.username)
      await Cognito.signIn(usernameFromDB, values.password, values.newPassword)
      await activateFirstTimeUser({})
      await updateStripeMeta({})
      setSubmitting(false)
      if (location.state && location.state.from) {
        afterLoggedIn(history, location.state.from)
      } else {
        afterLoggedIn(history)
      }
    } catch (error) {
      setSubmitting(false)
      console.info(error)
      // if no cognito user exist => legacy user flow check
      if (error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException') {
        if (isEmail.test(values.username)) {
          console.info('try fetching username and login with username')
          const username = await getUsernameByEmail(values.username)
          if (username && username.userNameFromEmail) {
            handleSignIn({ ...values, username: username.userNameFromEmail }, formikActions)
            return
          }
        } else {
          console.info('legacy sign in attempt...')
          try {
            signOut()
            await signInLegacyUser(values.username, values.password)
            afterLoggedIn(history)
            return
          } catch (e) {
            setFieldValue('formError', e.message)
            return
          }
        }
      }
      if (error.code === 'UserNotConfirmedException') {
        history.push('/auth/verify-account', { username: values.username })
        return
      }
      if (error.message === NEW_PASSWORD_REQUIRED) {
        setFieldValue('newPasswordRequired', true)
      } else {
        setFieldValue('formError', error.message)
      }
    }
  }
  const formValues =
    location.state && location.state.message ? { ...initialValues, formError: location.state.message } : initialValues
  return loading ? null : (
    <React.Fragment>
      <Helmet>
        <title>Sign In | ONN (by Curvo)</title>
      </Helmet>
      <Container padding="3rem" marginTop="4rem">
        <NormalText size="2rem" center marginBottom="2rem">
          Login
        </NormalText>
        <Formik
          initialValues={formValues}
          onSubmit={handleSignIn}
          validationSchema={validateSchema}
          render={props => <SignInForm {...props} />}
        />
        <NormalText size="0.75rem">
          New to ONN?{' '}
          <Button small link>
            <Link to="/auth/signup">Create New Account</Link>
          </Button>{' '}
          <Button small link marginLeft="auto">
            <Link to="/auth/forget-password">Forgot Password?</Link>
          </Button>
        </NormalText>
      </Container>
    </React.Fragment>
  )
}

SignIn.defaultProps = defaultProps

export default SignIn
