import React from 'react'
import { NormalText, Notify } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import { StripeCardType, editCardWithPhoneNumber } from '@curvo/apollo'
import { Container } from './ChangePasswordForm'
import BillingAddressForm from './BillingAddressForm'

const defaultProps = {}

type Props = typeof defaultProps & {
  card?: StripeCardType
  onRequestToClose: FuncVoid
  phoneNumber?: string | null
}

export const initialValues = {
  firstName: '',
  lastName: '',
  name: '',
  addressCity: '',
  addressLine1: '',
  addressLine2: '',
  addressCountry: { label: 'United States', value: 'United States' },
  addressZip: '',
  addressState: '',
  phoneNumber: '+1',
}

export type Values = typeof initialValues & {
  formError?: string
}

const handleFormSubmit =
  (cardId: string, onRequestToClose: FuncVoid) =>
  async (
    { firstName, lastName, name, phoneNumber, addressCountry, ...restValues }: Values,
    { setSubmitting, setFieldValue }: FormikActions<Values>,
  ) => {
    try {
      await editCardWithPhoneNumber({
        cardId,
        input: {
          name: name && name !== ' ' ? name : `${firstName}${lastName ? ` ${lastName}` : ''}`,
          addressCountry: addressCountry.value,
          ...restValues,
        },
        phoneNumber,
      })
      Notify.primary('Card was edited!')
      onRequestToClose()
    } catch (error) {
      setFieldValue('formError', error.message || 'Something went wrong!')
      setSubmitting(false)
    }
  }

const transformValues = ({
  addressCity,
  addressLine1,
  addressLine2,
  addressCountry,
  addressZip,
  addressState,
  name,
  phoneNumber,
}: StripeCardType & { phoneNumber?: string | null }): Values => ({
  firstName: '',
  lastName: '',
  addressCity: addressCity || '',
  addressLine1: addressLine1 || '',
  addressLine2: addressLine2 || '',
  addressCountry: addressCountry
    ? { label: addressCountry, value: addressCountry }
    : { label: 'United States', value: 'United States' },
  addressZip: addressZip || '',
  addressState: addressState || '',
  phoneNumber: phoneNumber || '',
  name: name || '',
})

const BillingAddressModal: React.SFC<Props> = ({ card, onRequestToClose, phoneNumber }) => {
  const values = card ? transformValues({ ...card, phoneNumber }) : initialValues
  const handleEditCard = handleFormSubmit(card!.id, onRequestToClose)
  return (
    <Container>
      <NormalText size="1.25rem" marginBottom="2rem">
        Edit Billing Address
      </NormalText>
      <Formik initialValues={values} onSubmit={handleEditCard} render={props => <BillingAddressForm {...props} />} />
    </Container>
  )
}

BillingAddressModal.defaultProps = defaultProps

export default BillingAddressModal
