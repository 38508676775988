import { MeConsumer, StripeCardType, InvoicesData } from '@curvo/apollo'
import { Button, Card, Flex, Modal, NormalText, Toggle } from '@curvo/common-ui'
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import LoadingIndicator from '../../components/LoadingIndicator'
import InvoiceDetails from './components/InvoiceDetails'
import PageLayout from './components/PageLayout'
import PaymentMethods from './components/PaymentMethods'
import BillingAddressModal from './components/BillingAddressModal'
import { formatDate } from './components/SubscriptionDetails'

const defaultProps = {}

type Props = typeof defaultProps & {}

const renderAddress = (card: StripeCardType | undefined) => {
  if (!card) {
    return 'Not specified'
  }
  const { addressCity, addressCountry, addressLine1, addressLine2, addressState, addressZip, name } = card
  if (!addressCity && !addressCountry && !addressLine1 && !addressLine2 && !addressState) {
    return 'Not specified'
  }
  return [
    <NormalText secondary size="1rem" key="address-name">
      {name}
    </NormalText>,
    <NormalText secondary size="1rem" key="address-line-1">
      {addressLine1}
    </NormalText>,
    <NormalText secondary size="1rem" key="address-line-2">
      {addressLine2}
    </NormalText>,
    <NormalText secondary size="1rem" key="address-line-3">
      {addressCity} {addressState} {addressCountry} {addressZip}
    </NormalText>,
  ]
}

const InvoiceTotal = styled(Flex)`
  min-width: 100px;
  align-items: center;
  justify-content: flex-end;
`

export const formatPrice = (price?: number | null) => {
  if (!price) {
    return '0'
  }
  return (price / 100).toFixed(2)
}

const Billing: React.SFC<Props> = () => (
  <PageLayout>
    <Helmet>
      <title>Billing | ONN (by Curvo)</title>
    </Helmet>
    <MeConsumer>
      {({ data, loading }) => {
        if (!data || loading) {
          return <LoadingIndicator />
        }
        const defaultCard = data.me.defaultSource
          ? data.me.sources.find(item => item.id === data.me.defaultSource)
          : undefined
        return (
          <React.Fragment>
            <PaymentMethods cards={data.me.sources} defaultSource={data.me.defaultSource} />
            <Card padding="2rem" flexDirection="row" marginBottom="2rem">
              <Flex flexDirection="column" flex={1}>
                <NormalText size="1.2rem" marginBottom="1rem">
                  Billing Address
                </NormalText>
                {renderAddress(defaultCard)}
              </Flex>
              <Flex flex={1} alignSelf="flex-start" justifyContent="flex-end">
                <Toggle>
                  {({ isActive, on, off }) => (
                    <React.Fragment>
                      <Button large onClick={on}>
                        Edit Address
                      </Button>
                      <Modal isOpen={isActive} onRequestToClose={off}>
                        <BillingAddressModal
                          card={defaultCard}
                          onRequestToClose={off}
                          phoneNumber={data.me.phoneNumber}
                        />
                      </Modal>
                    </React.Fragment>
                  )}
                </Toggle>
              </Flex>
            </Card>
            <Card padding="2rem" marginBottom="2rem">
              <NormalText size="1.2rem">Invoice History</NormalText>
              <NormalText secondary marginBottom="1rem">
                Here are all of your previous invoices
              </NormalText>
              <InvoicesData>
                {({ data: invoiceData, loading: invoiceLoading }) => {
                  if (!invoiceData || invoiceLoading) {
                    return <LoadingIndicator />
                  }
                  return (
                    <>
                      {invoiceData.invoices.data.map((item, itemIndex) => (
                        <Card
                          flexDirection="row"
                          padding="1rem"
                          key={item.id!}
                          marginBottom={itemIndex + 1 === invoiceData.invoices.data.length ? '0' : '0.5rem'}>
                          <Flex marginRight="3rem" alignItems="center">
                            <NormalText>{formatDate(item.date)}</NormalText>
                          </Flex>
                          <Flex flex={1} marginRight="1rem" alignItems="center">
                            <NormalText>Invoice Number: {item.number}</NormalText>
                          </Flex>
                          <Flex marginRight="1rem">
                            <Toggle>
                              {({ isActive, on, off }) => (
                                <React.Fragment>
                                  <Button link onClick={on}>
                                    View Invoice
                                  </Button>
                                  <Modal isOpen={isActive} onRequestToClose={off}>
                                    <InvoiceDetails data={item} onRequestToClose={off} />
                                  </Modal>
                                </React.Fragment>
                              )}
                            </Toggle>
                          </Flex>
                          <InvoiceTotal>
                            <NormalText>${formatPrice(item.total)}</NormalText>
                          </InvoiceTotal>
                        </Card>
                      ))}
                    </>
                  )
                }}
              </InvoicesData>
            </Card>
          </React.Fragment>
        )
      }}
    </MeConsumer>
  </PageLayout>
)

Billing.defaultProps = defaultProps

export default Billing
