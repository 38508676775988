import { MeContext } from '@curvo/apollo'
import { Container } from '@curvo/common-ui'
import React, { useContext } from 'react'
import { Redirect, Switch } from 'react-router'
import AuthorizedRoute from '../../components/AuthorizedRoute'
import Header from '../../components/Header'
import Layout from '../../components/Layout'
import Billing from './Billing'
import Profile from './Profile'
import SubAccount from './SubAccount'
const defaultProps = {}

type Props = typeof defaultProps & {}

const Account: React.SFC<Props> = () => {
  const { data } = useContext(MeContext)
  return (
    <Layout header={<Header title="Account" isLogoClickable={data && data.me ? true : false} />}>
      <Container>
        <Switch>
          <AuthorizedRoute path="/account/profile" component={Profile} />
          <AuthorizedRoute path="/account/sub-accounts" component={SubAccount} />
          <AuthorizedRoute path="/account/billing" component={Billing} />
          <Redirect to="/account/profile" />
        </Switch>
      </Container>
    </Layout>
  )
}

Account.defaultProps = defaultProps

export default Account
