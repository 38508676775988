import { Flex } from '@curvo/common-ui'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import CurvoLogo from '../../../assets/images/curvo-logo.svg'
import Layout from '../../../components/Layout'

const Container = styled(Flex)`
  min-width: 1140px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`

const Logo = styled('img')`
  width: 120px;
  height: auto;
`

const AuthLayout =
  (Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>) => (props: any) =>
    (
      <Layout showHeader={false}>
        <Container>
          <Logo src={CurvoLogo} />
          <Component {...props} />
        </Container>
      </Layout>
    )

export default AuthLayout
