import { Alert, Button, Colors, Flex, GroupInput, TextInput } from '@curvo/common-ui'
import { FormikHandlers, FormikState } from 'formik'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form } from './SignInForm'
import { Values } from './SignUp'

export const Row = styled(Flex)`
  margin-bottom: 1rem;
`

const defaultProps = {}

const PactSafe = styled('div')`
  a {
    color: ${Colors.Primary};
  }

  .ps-contract {
    padding-bottom: 0.5rem !important;
  }

  .ps-checkbox-container {
    font-size: 1rem !important;
  }
`

declare var _ps: any

type Props = Partial<typeof defaultProps> & FormikState<Values> & FormikHandlers

const SignUpForm: React.SFC<Props> = ({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
  const [isAgree, setIsAgree] = useState(false)
  const agreementKey = 'lookup-by-curvo'

  useEffect(() => {
    _ps('create', '721e69ab-720f-4525-af86-304abdd0a676')
    _ps('load', agreementKey, {
      container_selector: 'pactsafe-contract',
      signer_id_selector: 'email',
    })

    _ps.on('all', () => {
      if (_ps.getByKey(agreementKey) && _ps.getByKey(agreementKey).allChecked()) {
        setIsAgree(true)
      } else {
        setIsAgree(false)
      }
    })

    return () => {
      _ps.off()
      _ps('remove')
    }
  }, [])
  return (
    <Form onSubmit={handleSubmit}>
      {!!values.formError && (
        <Alert danger marginBottom="1rem">
          {values.formError}
        </Alert>
      )}
      <GroupInput>
        <TextInput
          name="firstName"
          value={values.firstName}
          placeholder="First Name"
          onChange={handleChange}
          error={touched.firstName ? errors.firstName : undefined}
        />
        <TextInput
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          placeholder="Last Name"
          error={touched.lastName ? errors.lastName : undefined}
        />
      </GroupInput>
      <TextInput
        name="username"
        value={values.username.toLowerCase()}
        onChange={handleChange}
        placeholder="Username"
        error={touched.username ? errors.username : undefined}
      />
      <TextInput
        name="email"
        value={values.email}
        onChange={handleChange}
        placeholder="Email"
        error={touched.email ? errors.email : undefined}
      />
      <TextInput
        name="password"
        value={values.password}
        onChange={handleChange}
        type="password"
        placeholder="Password"
        error={touched.password ? errors.password : undefined}
      />
      <TextInput
        name="confirmPassword"
        value={values.confirmPassword}
        onChange={handleChange}
        type="password"
        placeholder="Re-enter Password"
        error={touched.password ? errors.confirmPassword : undefined}
      />
      <PactSafe id="pactsafe-contract" />
      <Button
        disabled={!isAgree}
        primary
        block
        large
        marginBottom="1rem"
        marginTop="1rem"
        type="submit"
        loading={isSubmitting}>
        Create Account
      </Button>
    </Form>
  )
}

SignUpForm.defaultProps = defaultProps

export default SignUpForm
