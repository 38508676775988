import { Formik, FormikProps } from 'formik'
import React from 'react'
import styled from 'styled-components'
import TextInputWithSuggest from './TextInputWithSuggest'

const defaultProps = {}

const initialValues = {
  searchText: '',
}

type Values = typeof initialValues

type Props = Partial<typeof defaultProps> & {
  initialValues?: Values
  onSubmit: (values: Values) => void
}

const Form = styled('form')``

const TextInput = styled('input')`
  background: #ffffff;
  background-image: url("data:image/svg+xml;utf8,<svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-22.000000, -14.000000)' fill='#000000' fill-rule='nonzero'><g transform='translate(20.000000, 12.000000)'><path d='M18.0318994,16.6176858 L21.6066017,20.1923882 L20.1923882,21.6066017 L16.6176858,18.0318994 C15.078015,19.2635271 13.1250137,20 11,20 C6.02943725,20 2,15.9705627 2,11 C2,6.02943725 6.02943725,2 11,2 C15.9705627,2 20,6.02943725 20,11 C20,13.1250137 19.2635271,15.078015 18.0318994,16.6176858 Z M11,18 C14.8659932,18 18,14.8659932 18,11 C18,7.13400675 14.8659932,4 11,4 C7.13400675,4 4,7.13400675 4,11 C4,14.8659932 7.13400675,18 11,18 Z'></path></g></g></g></svg>");
  background-repeat: no-repeat;
  background-position: center left 10px;
  border: 1px solid rgba(137, 157, 166, 0.35);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 0.75rem 0.75rem 0.75rem 40px;
  font-weight: 600;
  min-width: 500px;
`

const HeaderSearchInput: React.SFC<Props> = props => (
  <Formik
    initialValues={props.initialValues || initialValues}
    onSubmit={props.onSubmit}
    render={({ values, handleSubmit, setFieldValue, submitForm }: FormikProps<Values>) => (
      <Form onSubmit={handleSubmit}>
        <TextInputWithSuggest
          inputProps={{
            placeholder: 'Enter name, part number or product line...',
            showError: false,
            large: true,
            flex: 1,
            name: 'searchText',
            autoComplete: 'off',
          }}
          value={values.searchText}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
          fieldValue="searchText">
          <TextInput />
        </TextInputWithSuggest>
        <input type="submit" hidden />
      </Form>
    )}
  />
)

HeaderSearchInput.defaultProps = defaultProps

export default HeaderSearchInput
