import {
  MeConsumer,
  BillingEnumType,
  BillingReasonEnumType,
  StripePlanType,
  addCreditCardWithPhoneNumberAndSubscription,
  StripeCouponData,
} from '@curvo/apollo'
import { Alert, NormalText, Notify, Flex } from '@curvo/common-ui'
import { Formik, FormikActions } from 'formik'
import React from 'react'
import Helmet from 'react-helmet'
import { Redirect, RouteComponentProps, StaticContext } from 'react-router'
import { CardElement, Elements, injectStripe, ReactStripeElements } from 'react-stripe-elements'
import styled from 'styled-components'
import CardInput from '../../components/CardInput'
import LoadingIndicator from '../../components/LoadingIndicator'
import BillingAddressForm from './components/BillingAddressForm'
import { initialValues, Values } from './components/BillingAddressModal'
import InvoiceDetails from './components/InvoiceDetails'
import { getCurvoPlanAmount } from './components/Plan'

const defaultProps = {}

const initialState = {
  loading: false,
  fromError: '',
}

type Props = typeof defaultProps &
  ReactStripeElements.InjectedStripeProps &
  RouteComponentProps<
    {},
    StaticContext,
    {
      ref?: string
      coupon?: string
      plans?: StripePlanType[]
    }
  > & {}

type State = Partial<Readonly<typeof initialState>>

const Container = styled(Flex)`
  width: 100%;
  justify-content: center;
`

const StyledFlex = styled(Flex)`
  width: 600px;
`

class Billing extends React.Component<Props, State> {
  static defaultProps = defaultProps

  readonly state: State = initialState

  private handleCreateToken = async (
    { firstName, lastName, addressCountry, phoneNumber, addressZip: _addressZip, ...restValue }: Values,
    { setSubmitting }: FormikActions<Values>,
  ) => {
    const name = `${firstName}${lastName ? ` ${lastName}` : ''}`
    let input: any
    if (Object.keys(restValue).length > 0) {
      input = {
        ...restValue,
        addressCountry: addressCountry ? addressCountry.value : undefined,
        name,
      }
    }
    if (this.props.stripe) {
      try {
        const { token } = await this.props.stripe.createToken()
        if (token) {
          await addCreditCardWithPhoneNumberAndSubscription({
            tokenId: token.id,
            input,
            phoneNumber,
            planIds: (this.props.location.state.plans || []).map(plan => plan.id),
            coupon: this.props.location.state && this.props.location.state.coupon,
          })
          setSubmitting(false)
          this.props.history.push('/')
          // @Todo: add plans ids
          return
        }
        setSubmitting(false)
        this.setState({ fromError: 'Card info is required!' })
      } catch (error) {
        this.setState({ fromError: error.message || 'Something went wrong!' })
        setSubmitting(false)
      }
    }
  }

  render() {
    const { fromError } = this.state
    const { location } = this.props
    const { state } = location

    return (
      <MeConsumer>
        {({ data, loading }) => {
          if (!data || loading) {
            return <LoadingIndicator center />
          }

          if (data.me.hasPaymentInfo) {
            if (!data.me.hasSubscription) {
              return <Redirect to="/setup/subscription" />
            }
            return <Redirect to="/" />
          }

          if (!state || !state.plans) {
            return <Redirect to="/setup/subscription" />
          }
          const invoiceSubTotal = state.plans.reduce(
            (acc, plan) => acc + parseFloat(getCurvoPlanAmount(plan).replace(',', '')) * 100,
            0,
          )

          return (
            <StripeCouponData variables={{ id: state.coupon || 'null' }} selfHandleError={true}>
              {({ data: couponData, error }) => {
                if (state.coupon && error) {
                  Notify.danger('Invalid coupon')
                }
                const total =
                  couponData && couponData.coupon
                    ? couponData.coupon.amountOff
                      ? invoiceSubTotal - couponData.coupon.amountOff
                      : (invoiceSubTotal * (100 - couponData.coupon.percentOff!)) / 100
                    : invoiceSubTotal
                return (
                  <Container>
                    <Helmet>
                      <title>Billing | ONN (by Curvo)</title>
                    </Helmet>

                    <InvoiceDetails
                      isBilled={false}
                      data={{
                        billing: BillingEnumType.ChargeAutomatically,
                        number: '',
                        billingReason: BillingReasonEnumType.SubscriptionCreate,
                        date: Date.now(),
                        lines: {
                          data: state.plans
                            ? state.plans.map(plan => ({
                                plan,
                                description: plan.nickname,
                                amount: parseFloat(getCurvoPlanAmount(plan).replace(',', '')) * 100,
                              }))
                            : [],
                        },
                        discount: couponData &&
                          couponData.coupon && {
                            coupon: couponData.coupon,
                            customer: data.me.id,
                          },
                        total,
                        subtotal: invoiceSubTotal,
                      }}
                    />

                    <StyledFlex padding="3rem" flexDirection="column">
                      <NormalText size="2rem" center>
                        Billing Info
                      </NormalText>
                      <NormalText center primary marginBottom="2rem">
                        * All Fields Required
                      </NormalText>
                      {fromError && (
                        <Alert danger marginBottom="1rem">
                          {fromError}
                        </Alert>
                      )}
                      <NormalText secondary={false}>Card Details</NormalText>
                      <CardInput marginTop="1rem">
                        <CardElement />
                      </CardInput>
                      <NormalText secondary={false} marginTop="2rem" marginBottom="1rem">
                        Billing Address
                      </NormalText>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleCreateToken}
                        render={props => <BillingAddressForm total={total} {...props} isAddNew />}
                      />
                    </StyledFlex>
                  </Container>
                )
              }}
            </StripeCouponData>
          )
        }}
      </MeConsumer>
    )
  }
}

const InjectedBilling = injectStripe(Billing)

const BillingInfo = (props: any) => (
  <Elements>
    <InjectedBilling {...props} />
  </Elements>
)

export default BillingInfo
