import { MeConsumer, UserTypeEnum } from '@curvo/apollo'
import { Colors, Flex, getMargin, MarginProps } from '@curvo/common-ui'
import React from 'react'
import Helmet from 'react-helmet'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import LoadingIndicator from '../../components/LoadingIndicator'
import Cognito from '../../configs/Cognito'
import { LegacyUserWarning } from '../Home/components/LegacyUserWarning'
import ChangePassword from './components/ChangePassword'
import DeactivateAccount from './components/DeativeAccount'
import PageLayout from './components/PageLayout'
import ProfileDetails from './components/ProfileDetails'
import SubscriptionDetails from './components/SubscriptionDetails'

const defaultProps = {}

type Props = typeof defaultProps & RouteComponentProps & {}

export const BorderLineVertical = styled(Flex)<MarginProps>`
  align-self: stretch;
  background: ${Colors.Border};
  width: 1px;
  ${getMargin};
`

BorderLineVertical.defaultProps = {
  margin: '0 3rem',
}

export const BorderLineHorizontal = styled(Flex)<MarginProps>`
  height: 1px;
  background: ${Colors.Border};
  ${getMargin};
`

BorderLineHorizontal.defaultProps = {
  margin: '3rem 0 1rem',
}

const Profile: React.SFC<Props> = ({ history }) => {
  const handleSignOut = () => {
    Cognito.signOut()
    history.push('/auth')
  }
  return (
    <PageLayout>
      <Helmet>
        <title>Profile | ONN (by Curvo)</title>
      </Helmet>
      <MeConsumer>
        {({ data, loading }) => {
          if (!data || loading) {
            return <LoadingIndicator />
          }
          return (
            <React.Fragment>
              <LegacyUserWarning />
              <ProfileDetails data={data.me} />
              <ChangePassword data={data.me} />
              {data.me.userType === UserTypeEnum.Parent && <SubscriptionDetails data={data.me} />}
              <DeactivateAccount onSignOut={handleSignOut} />
            </React.Fragment>
          )
        }}
      </MeConsumer>
    </PageLayout>
  )
}

Profile.defaultProps = defaultProps

export default Profile
