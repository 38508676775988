import { AvgPriceData, Part, PartImagesData, PricesData } from '@curvo/apollo'
import { Button, Card, Colors, darken, Flex, getMargin, MarginProps, Tooltip } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import ImageGallery from '../../../components/ImageGallery'
import LoadingIndicator from '../../../components/LoadingIndicator'
import AvgPrices from './AvgPrices'
import { BigChart, SmallChart } from './Chart'
import Prices from './Prices'
import { getCreatedDate, ImageWrapper, MetaData, Row, Text, writeBrandManufacturerName } from './ResultItem'

const defaultProps = {}

type Props = Partial<typeof defaultProps> & {
  data: Part
  handleClose: () => void
}

const Container = styled(Card)`
  background: white;
  max-height: 80vh;
  min-width: 940px;
`

const PartInfo = styled(Flex)`
  padding: 1rem;
`

const Breakline = styled('div')<MarginProps>`
  width: 100%;
  height: 1px;
  background: ${Colors.Border};
  ${getMargin}
`

const ChartGroup = styled(Flex)`
  padding: 20px;
`

const ResultItemDetails: React.SFC<Props> = ({ data, handleClose }) => (
  <Container header={false} flexDirection="column">
    <PartInfo>
      <PartImagesData variables={{ partId: data.id }}>
        {({ data: imageData, loading }) => {
          if (loading || !imageData) {
            return <LoadingIndicator center />
          }
          return imageData.partImages && imageData.partImages.length ? (
            <ImageGallery data={imageData.partImages} marginRight="1rem" />
          ) : (
            <ImageWrapper marginRight="1rem">No Image</ImageWrapper>
          )
        }}
      </PartImagesData>
      <Flex flexDirection="column" flex="1">
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" flex={2}>
            <Flex flexWrap="wrap">
              <Text size="1rem" bold>
                {data.partName}&nbsp;
              </Text>
              {data.createdAt && (
                <Text secondary>
                  add on
                  {getCreatedDate(data.createdAt)}
                </Text>
              )}
            </Flex>
            <Flex alignItems="baseline" flexWrap="wrap">
              {writeBrandManufacturerName(data)}
              &nbsp;in&nbsp;
              <Text primary>
                {data.gic.name}&nbsp;(GIC {data.gic.id})
              </Text>
            </Flex>
          </Flex>

          <Text size="1.75rem" flex={3} right>
            {data.partNumber}
          </Text>
        </Flex>

        <Breakline marginTop="0.5rem" marginBottom="0.5rem" />
        <Flex justifyContent="space-between" alignItems="center">
          <PricesData variables={{ partId: data.id }}>
            {({ data: priceData, loading }) => {
              if (loading || !priceData) {
                return <LoadingIndicator center />
              }
              return <Prices data={priceData.prices} />
            }}
          </PricesData>
          <Flex padding="0 0.5em" />
          <AvgPriceData
            variables={{
              condition: { partId: data.id },
            }}>
            {({ data: avgData, loading }) => {
              if (loading || !avgData) {
                return <LoadingIndicator center />
              }
              const items = avgData.averagePrices
              const chartData = items.map(item => {
                return {
                  x: +item.periodStartDate,
                  y: item.averagePrice,
                }
              })
              return (
                <AvgPrices data={avgData.averagePrices}>
                  <SmallChart chartData={chartData} color={Colors.Primary} />
                </AvgPrices>
              )
            }}
          </AvgPriceData>
        </Flex>
        {data.description && (
          <React.Fragment>
            <Breakline marginTop="0.5rem" marginBottom="0.5rem" />
            <Text>{data.description}</Text>
          </React.Fragment>
        )}
        <Row flexDirection="column">
          <Flex marginBottom="0.5rem">
            <MetaData heading="Type 1" content={data.typeOne ? data.typeOne.name : '-'} />
            <MetaData heading="Type 2" content={data.typeTwo ? data.typeTwo.name : '-'} flex="3" />
          </Flex>
          <Flex>
            <MetaData heading="Product line" content={data.productLine ? data.productLine.name : '-'} />
            <MetaData
              heading="Segment"
              content={data.segmentation && data.segmentation.segment ? data.segmentation.segment.name : '-'}
            />
            <MetaData heading="Sizes" content={`${data.sizeOne}x${data.sizeTwo}x${data.sizeThree}`} />
            <Flex flexDirection="column" flex="1">
              <Text secondary size="0.625rem" uppercase>
                Material
              </Text>
              <Text size="0.75rem">
                <Tooltip
                  content={data.material ? data.material.name : ''}
                  disable={data.material && data.material.name.length ? false : true}
                  flex={undefined}
                  marginRight="0.5em"
                  highlightedColor={darken(Colors.Secondary, 0.3)}>
                  {data.material && !!data.material.name.length ? data.material.id : '-'}
                </Tooltip>
              </Text>
            </Flex>
          </Flex>
        </Row>
      </Flex>
    </PartInfo>
    <Breakline marginTop={40} marginBottom={40} />
    <ChartGroup justifyContent="center">
      <AvgPriceData
        variables={{
          condition: { partId: data.id },
        }}>
        {({ data: avgData, loading }) => {
          if (loading || !avgData) {
            return <LoadingIndicator center />
          }
          const items = avgData.averagePrices
          if (!items.length) {
            return <div>No data</div>
          }
          const chartData = items.map(item => {
            return {
              x: +item.periodStartDate,
              y: item.averagePrice,
            }
          })
          return <BigChart chartData={chartData} />
        }}
      </AvgPriceData>
    </ChartGroup>
    <Breakline marginTop={40} marginBottom={20} />
    <PartInfo justifyContent="flex-end">
      <a
        href={`mailto:support@curvolabs.com?subject=Feedback for Part ${data.partNumber}${
          data.manufacturer && ` by ${data.manufacturer.name}`
        }`}
        target="_blank"
        rel="noopener noreferrer">
        <Button light>Send Feedback</Button>
      </a>
      <Button onClick={handleClose} light marginLeft="6px">
        Close
      </Button>
    </PartInfo>
  </Container>
)

ResultItemDetails.defaultProps = defaultProps

export default ResultItemDetails
